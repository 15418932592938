<template>
  <div class="multi-print-control flex-group space-between">
    <CheckBox
      class="checkbox"
      :label="checkboxLabel"
      value="select-all-printable-list"
      :checked="checked"
      :indeterminate="indeterminate"
      @select="toggleSelect()"
    />
    <pn-button
      v-if="forPrintCount"
      :key="forPrintCount"
      :icon="printIcon"
      class="print-button"
      left-icon
      @click="print"
      >{{ printButtonLabel }}</pn-button
    >
  </div>
</template>

<script>
import CheckBox from '@/shared/components/CheckBox.vue'
import { print as printIcon } from '@/shared/constants/icons.js'

export default {
  name: 'MultiplePrintControl',
  components: {
    CheckBox
  },
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    indeterminate: {
      type: Boolean,
      default: false
    },
    forPrintCount: {
      type: Number,
      default: 0
    },
    printableCount: {
      type: Number,
      default: 0
    }
  },
  emits: ['toggleSelect', 'print'],
  data() {
    return {
      printIcon
    }
  },
  computed: {
    checkboxLabel() {
      const label = this.$i18n.t('DELIVERY_LIST.SELECT_ALL_PRINTABLE')
      if (this.printableCount) {
        return `${label} (${this.printableCount})`
      }
      return label
    },
    printButtonLabel() {
      return `${this.$i18n.t('DELIVERY_LIST.PRINT_LISTS')} (${
        this.forPrintCount
      })`
    }
  },
  methods: {
    toggleSelect() {
      this.$emit('toggleSelect', this.checked)
    },
    print() {
      this.$emit('print')
    }
  }
}
</script>

<style scoped lang="scss">
.checkbox {
  padding: 1em 0.5em;
}
</style>
