<template>
  <div class="flex-group">
    <div class="toggle">
      <label for="toggle-is-single-date"
        >{{ $t('FORECAST.SELECT_SINGLE_DATE') }}:</label
      >
      <pn-toggle-switch
        :checked="isSingleDate"
        toggleid="toggle-is-single-date"
        @change="toggleSingleDateChange"
      />
    </div>
    <div>
      <pn-date-picker
        :range="!isSingleDate"
        :start="startDate"
        :end="endDate"
        @dateselection="handleDateChange"
      ></pn-date-picker>
    </div>
    <div class="range-controls">
      <pn-button
        v-for="choice in ranges"
        :key="choice"
        class="choice-chip"
        small
        :appearance="selectedRange == choice ? 'dark' : 'light'"
        @click="handleRangeChange(choice)"
      >
        {{ choice }}
      </pn-button>
    </div>
  </div>
</template>

<script>
import { FORECAST_OPTIONS } from '../forecastOptions'

export default {
  name: 'RangeControls',
  emits: ['didChangeRange', 'didChangeDate'],
  data() {
    return {
      startDate: '',
      endDate: '',
      selectedRange: FORECAST_OPTIONS.WEEK,
      ranges: Object.keys(FORECAST_OPTIONS).map((key) => FORECAST_OPTIONS[key]),
      isSingleDate: false
    }
  },
  watch: {
    selectedRange(val) {
      if (val === '') {
        this.$emit('didChangeRange', this.selectedRange)
      }
    },
    isSingleDate(val) {
      if (val) {
        this.endDate = ''
      }
    }
  },
  mounted() {
    this.$emit('didChangeRange', this.selectedRange)
  },
  methods: {
    handleRangeChange(event) {
      this.selectedRange = event
      this.startDate = ''
      this.endDate = ''
      this.$emit('didChangeRange', this.selectedRange)
    },
    handleDateChange(event) {
      this.selectedRange = ''
      if (this.isSingleDate) {
        this.startDate = event.detail
        this.endDate = this.startDate
      } else {
        this.startDate = event.detail.start
        this.endDate = event.detail.end
      }
      this.$emit('didChangeDate', {
        startDate: this.startDate,
        endDate: this.endDate
      })
    },
    toggleSingleDateChange() {
      this.isSingleDate = !this.isSingleDate
      this.selectedRange = ''
    }
  }
}
</script>

<style scoped lang="scss">
.range-controls {
  padding-bottom: unset;
  width: 100%;
}
.flex-group {
  align-items: end !important;
}
.choice-chip {
  margin-right: 8px;
  margin-bottom: 8px;
}
.label2 {
  margin: unset;
}
.toggle {
  display: flex;
  align-items: center;
}

label {
  margin-right: 8px;
  font-weight: 500;
}
</style>
