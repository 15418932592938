<template>
  <PageContent
    :heading="heading"
    :back-path="'/delivery-list'"
    :back-text="$t('APP.BACK')"
    :is-loading="loading"
    :is-empty="isEmpty"
  >
    <EmailInputModal
      :title="$t('DELIVERY_LIST.SEND_CSV_TO_EMAIL')"
      :show="emailModal.show"
      :email="currentEmail"
      @did-close="emailModal.show = false"
      @did-send="handleDidConfirmEmail"
    />
    <PrintOpenModal
      :show="printPdfModal.show"
      :is-loading="printPdfModal.isLoading"
      :url="printPdfModal.url"
      @did-close="printPdfModal.show = false"
    />
    <ConfirmModal
      class="print-modal"
      :title="$t('DELIVERY_LIST.PRINT_LIST')"
      :message="printModal.getMessage()"
      :show="printModal.show"
      :confirm-button-title="$t('DELIVERY_LIST.PRINT_LIST')"
      @confirm="confirmPrintList"
      @cancel="printModal.show = false"
    />

    <ConfirmModal
      class="acknowledge-modal"
      :title="$t('DELIVERY_LIST.ACKNOWLEDGE_LIST')"
      :message="acknowledgeModal.getMessage()"
      :show="acknowledgeModal.show"
      :confirm-button-title="$t('DELIVERY_LIST.CONFIRM_ACKNOWLEDGE')"
      @confirm="confirmAcknowledgeList"
      @cancel="acknowledgeModal.show = false"
    />

    <ConfirmModal
      class="delete-modal"
      :message="deleteModal.getMessage()"
      :show="deleteModal.show"
      @cancel="deleteModal.show = false"
      @confirm="confirmDelete"
    />

    <div v-if="!loading && current" class="content">
      <div class="sub-heading">
        <h3>
          {{ $t('DELIVERY_LIST.REGISTERED_ITEMS_FOR') }}
          {{ current.agreementName }}
        </h3>

        <pn-search-field
          :placeholder="$t('DELIVERY_LIST.SEARCH_ITEMS_IN_LIST')"
          button="icon"
          light
          @update="filters.searchText = $event.detail"
        />

        <div class="list-controls">
          <div v-if="!isForDelivery" class="seal-toast">
            <span class="label1">{{ $t('DELIVERY_LIST.SEAL_ID') }}:</span>

            <span v-if="current && current.seals">
              {{ current.seals[0].sealId }}
            </span>

            <pn-icon :symbol="barcode" small="false" color="black" />
          </div>
          <form v-else @submit.prevent="addItemToList">
            <div>
              <pn-input
                label="Item"
                class="main-input"
                :value="draftId"
                @input="draftId = $event.target.value"
              />

              <pn-button
                type="button"
                appearance="light"
                :icon="plus"
                @click="addItemToList"
              >
                {{ $t('DELIVERY_LIST.ADD_ITEM') }}
              </pn-button>
            </div>
          </form>

          <div class="actions">
            <pn-button
              v-if="isForPrint"
              :icon="envelope"
              class="email-button"
              appearance="light"
              left-icon
              @click="emailAsCsv"
            >
              {{ $t('DELIVERY_LIST.EMAIL_CSV') }}
            </pn-button>
            <pn-button
              v-if="isForPrint"
              :key="reprintOptions.isForReprint"
              :icon="printIcon"
              class="print-button"
              left-icon
              @click="print"
            >
              {{
                reprintOptions.isForReprint
                  ? $t('DELIVERY_LIST.REPRINT_LIST')
                  : $t('DELIVERY_LIST.PRINT_LIST')
              }}
            </pn-button>
            <pn-button
              v-if="isForDelivery"
              :icon="check"
              class="acknowledge-button"
              @click="acknowledgeList"
            >
              {{ $t('DELIVERY_LIST.ACKNOWLEDGE_LIST') }}
            </pn-button>
          </div>
        </div>
      </div>

      <div v-for="(item, index) in visibleItems" :key="index" class="item-row">
        <div class="column">
          <span class="label1">
            <text-highlight
              highlight-class-name="highlight"
              :search-words="[filters.searchText] || []"
              :auto-escape="true"
              :text-to-highlight="item.itemId ? item.itemId : ''"
            />
          </span>
        </div>

        <div v-if="isForDelivery" class="column actions">
          <pn-button
            appearance="warning"
            :icon="trash_can"
            small
            @click="remove(item)"
          >
            {{ $t('APP.REMOVE') }}
          </pn-button>
        </div>
      </div>

      <NavPagination
        v-if="filteredItems.length"
        :lists="filteredItems"
        :per-page="10"
        :total="filteredItems.length"
        @page-changed="updatePage"
      />
    </div>
  </PageContent>
</template>

<script>
import EmailInputModal from '@/shared/components/EmailInputModal.vue'
import NavPagination from '@/shared/components/NavPagination.vue'
import PageContent from '@/shared/components/PageContent.vue'
import PrintOpenModal from '@/shared/components/PrintOpenModal.vue'
import useEmailHandler from '@/shared/composables/useEmailHandler.js'
import usePrint from '@/shared/composables/usePrint.js'
import useToast from '@/shared/composables/useToast.js'
import {
  barcode,
  check,
  envelope,
  plus,
  print as printIcon,
  trash_can
} from '@/shared/constants/icons.js'
import genericListMixin from '@/shared/mixins/genericListMixin.js'
import TextHighlight from 'vue-highlight-words'
import { mapActions, mapState } from 'vuex'
import { heading } from './constants'

export default {
  name: 'DeliveryListDetails',
  components: {
    PageContent,
    NavPagination,
    TextHighlight,
    EmailInputModal,
    PrintOpenModal
  },
  mixins: [genericListMixin],
  setup() {
    const { successToast, showToaster } = useToast()
    const {
      prepareForPrint,
      generateReportErrorToast,
      printModal: printPdfModal
    } = usePrint()
    const { emailModal } = useEmailHandler()
    return {
      successToast,
      showToaster,
      prepareForPrint,
      generateReportErrorToast,
      printPdfModal,
      emailModal
    }
  },
  data() {
    return {
      heading,
      filters: {
        searchText: ''
      },
      draftId: '',
      draftItems: [],
      reprintOptions: {
        id: null,
        isForReprint: false
      },
      acknowledgeModal: {
        show: false,
        getMessage: () => {
          return `${this.$i18n?.t(
            'DELIVERY_LIST.CONFIRM_ACKNOWLEDGE_MESSAGE'
          )} ${this.current?.items?.length} ${this.$i18n
            .t('DELIVERY_LIST.ITEMS')
            .toLowerCase()}. 
          ${this.$i18n?.t('DELIVERY_LIST.CONFIRM_CONTINUE')}?`
        }
      },
      printModal: {
        show: false,
        getMessage: () => {
          return `${this.$i18n?.t('DELIVERY_LIST.CONFIRM_PRINT_MESSAGE')} ${
            this.current?.items?.length
          } ${this.$i18n?.t('DELIVERY_LIST.ITEMS').toLowerCase()}. 
          ${this.$i18n?.t('DELIVERY_LIST.CONFIRM_CONTINUE')}?`
        }
      },
      deleteModal: {
        data: null,
        show: false,
        getMessage: () => {
          if (this.deleteModal.data) {
            return `${this.$i18n?.t('DELIVERY_LIST.CONFIRM_REMOVE')}: ${
              this.deleteModal.data.itemId
            }?`
          }
          return ''
        }
      },
      storeSubscription: null,
      barcode,
      check,
      envelope,
      plus,
      printIcon,
      trash_can
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapState('deliverylists', ['current']),
    ...mapState('deliveryAgreements', ['items']),
    isForPrint() {
      return this.current?.state !== 'delivered'
    },
    isForDelivery() {
      return this.current?.state === 'readyForDelivery'
    },
    isDelivered() {
      return this.current?.state === 'delivered'
    },
    isEmpty() {
      return this.visibleItems.length === 0
    },
    currentEmail() {
      const filtered = this.items?.filter((item) => {
        return item.id === this.current.agreementId
      })
      if (filtered?.length) {
        return filtered[0].email
      }
      return ''
    }
  },
  watch: {
    'filters.searchText': function (val) {
      this.filterByQuery(val)
    },
    current(val) {
      if (val && val.items) {
        this.mainItems = val.items
      }
    },
    isForDelivery(val) {
      if (val) {
        this.draftItems = this.current.items
        this.mainItems = this.draftItems
      }
    }
  },
  mounted() {
    this.subscribeMutations()
    const deliveryListId = this.$route?.params?.id
    if (deliveryListId) {
      this.search({ id: deliveryListId })
      this.mainItems = this.current?.items
    }
  },
  unmounted() {
    if (this.storeSubscription) {
      this.storeSubscription()
    }
  },
  methods: {
    ...mapActions('deliverylists', [
      'search',
      'printDeliveryList',
      'acknowledgeDeliveryList',
      'deleteItem',
      'addItem',
      'sendEmail'
    ]),
    ...mapActions('retail', ['getPdf']),
    ...mapActions('deliveryAgreements', ['getDeliveryAgreements']),
    subscribeMutations() {
      this.storeSubscription = this.$store.subscribe((mutation) => {
        if (
          mutation.type === 'deliverylists/didPrintList' ||
          mutation.type === 'retail/didGetPdf'
        ) {
          this.handleDidGetBase64DataForPrint(mutation?.payload)
        } else if (mutation.type === 'deliverylists/didAcknowledgeList') {
          this.showDidAcknowledgeState()
        } else if (mutation.type === 'deliverylists/didAddItem') {
          this.showDidAddItemState()
        } else if (mutation.type === 'deliverylists/didDeleteItem') {
          this.showDidDeleteItemState(mutation?.payload?.message)
        } else if (mutation.type === 'deliverylists/didSendEmail') {
          this.showDidSendEmailState(mutation?.payload?.message)
        }
      })
    },
    print() {
      if (this.reprintOptions.isForReprint) {
        this.getPdf(this.reprintOptions.id)
      } else {
        this.printModal.show = true
      }
    },
    confirmPrintList() {
      this.printModal.show = false
      this.printDeliveryList(this.current.deliveryListId)
    },
    acknowledgeList() {
      this.acknowledgeModal.show = true
    },
    confirmAcknowledgeList() {
      this.acknowledgeModal.show = false
      this.acknowledgeDeliveryList(this.current.deliveryListId)
    },
    addItemToList() {
      if (this.draftId) {
        this.addItem({ id: this.current?.deliveryListId, itemId: this.draftId })
      }
    },
    remove(item) {
      this.deleteModal.data = item
      this.deleteModal.show = true
    },
    confirmDelete() {
      const item = this.deleteModal.data
      this.mainItems = this.mainItems.filter(
        (anItem) => anItem.itemId !== item.itemId
      )
      this.deleteItem(item.itemId)
      this.deleteModal.show = false
    },
    showDidPrintState() {
      this.successToast.message = this.$i18n?.t('DELIVERY_LIST.LIST_PRINTED')
      this.showToaster(this.successToast)
    },
    showDidAcknowledgeState() {
      this.successToast.message = this.$i18n?.t(
        'DELIVERY_LIST.LIST_ACKNOWLEDGED'
      )
      this.showToaster(this.successToast)
      this.$router.push({ path: '/delivery-list' })
    },
    showDidAddItemState() {
      this.mainItems.unshift({ itemId: this.draftId })
      this.draftId = ''
      window.scrollTo(0, document.body.scrollHeight)
    },
    showDidDeleteItemState(message = 'Successfully removed item.') {
      this.deleteModal.data = null
      this.successToast.message = message
      this.showToaster(this.successToast)
    },
    showDidSendEmailState(message = 'Email sent') {
      this.successToast.message = message
      this.showToaster(this.successToast)
    },
    handleDidGetBase64DataForPrint(payload) {
      const pdfData = payload?.data
      const printId = payload?.id
      if (pdfData) {
        this.handlePrintData(pdfData)
      } else if (printId) {
        this.handleForReprint(printId)
      } else {
        this.showToaster(this.generateReportErrorToast)
      }
    },
    async handlePrintData(data) {
      this.printPdfModal.show = true
      this.printPdfModal.isLoading = true
      this.printPdfModal.url = await this.prepareForPrint(data)
      this.printPdfModal.isLoading = false
      this.showDidPrintState()
    },
    handleForReprint(id) {
      this.reprintOptions.id = id
      this.reprintOptions.isForReprint = true
      this.showToaster(this.generateReportErrorToast)
    },
    emailAsCsv() {
      if (this.current?.state !== 'delivered' && this.items.length === 0) {
        this.getDeliveryAgreements()
      }
      this.emailModal.show = true
    },
    handleDidConfirmEmail(email) {
      this.emailModal.show = false
      this.emailModal.email = email
      this.sendEmail({ id: this.current?.deliveryListId, email: email })
    }
  }
}
</script>

<style lang="scss" scoped>
.sub-heading {
  padding-bottom: 1em;
  border-bottom: solid 1px $gray50;

  .list-controls {
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .actions {
      display: flex;
      grid-gap: 8px;
    }
  }

  pn-search-field {
    width: 100%;
  }

  form div {
    display: flex;
    grid-gap: 1em;
    align-items: flex-end;
  }

  form pn-input {
    margin-bottom: unset;
    min-width: 280px;
  }
}
.seal-toast {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  padding: 1em;
  border: 0.1rem solid $blue700;
  border-radius: 0.8rem;
  background: $blue50;
}
.print-button {
  min-width: 200px;
}
.item-row {
  padding: 1em;
}
.column {
  flex: 1;
}

.actions {
  justify-content: flex-end;
}

::v-deep(pn-modal.confirm-modal .pn-modal) {
  max-width: 450px !important;
}

@media (max-width: 700px) {
  .column {
    flex: unset;
  }
  .actions {
    width: 100%;
    pn-button {
      width: 50%;
    }
  }
}

@media (max-width: $breakpoint-phone) {
  .item-row .column {
    width: 100%;
    margin-bottom: 4px;
  }
  .item-row .column .badge {
    margin: unset;
  }
}
</style>
