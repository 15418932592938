import { cipher } from '@postnord/retail-backoffice-components/dist'
import axios from 'axios'

const { VUE_APP_EBUTIKEN_BASE_URL } = process.env

axios.defaults.withCredentials = true

export const serviceInstance = axios.create({
  baseURL: `${VUE_APP_EBUTIKEN_BASE_URL}`
})

export const generateRandomString = () => {
  const _cipher = cipher(`${process.env.VUE_APP_BACKOFFICE_SALT}`)
  return _cipher(`eshop${new Date().toLocaleString()}`)
}

export default {
  login: async (token) => {
    const returnUrl = `${VUE_APP_EBUTIKEN_BASE_URL}/handla-online?${generateRandomString()}`
    const url = `/login?returnUrl=${returnUrl}`
    const headers = {
      Authorization: token,
      'x-pn-bc': 'X'
    }
    return serviceInstance.get(url, { headers })
  }
}
