export const heading = {
  title: 'DELIVERY_LIST.TITLE2',
  description: 'DELIVERY_LIST.DESCRIPTION',
  useTranslation: true
}

export const DELIVERY_LIST_STATUS = {
  readyForPrint: 'Ready for print',
  readyForDelivery: 'Ready for delivery',
  delivered: 'Delivered'
}

export const DELIVERY_LIST_STATUS_I18N = {
  readyForPrint: 'DELIVERY_LIST.READY_FOR_PRINT',
  readyForDelivery: 'DELIVERY_LIST.READY_FOR_DELIVERY',
  delivered: 'DELIVERY_LIST.DELIVERED'
}
