import { PDFDocument } from 'pdf-lib'

export default function useMultiplePrint() {
  const createUrlFromBase64Array = async (dataArray) => {
    const mergedPdf = await PDFDocument.create()
    const copiedPages = []

    for (const base64Data of dataArray) {
      const pdf = await PDFDocument.load(base64Data)
      const copiedPagesFromPdf = await mergedPdf.copyPages(
        pdf,
        pdf.getPageIndices()
      )
      copiedPages.push(...copiedPagesFromPdf)
    }

    copiedPages.forEach((page) => mergedPdf.addPage(page))

    const mergedPdfFile = await mergedPdf.save()

    const pdfBlob = new Blob([mergedPdfFile], { type: 'application/pdf' })
    return URL.createObjectURL(pdfBlob)
  }

  return {
    createUrlFromBase64Array
  }
}
