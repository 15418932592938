import { ref } from 'vue'

export default function useErrorToast() {
  const errorToast = ref({
    hidden: true,
    message: '',
    closable: false
  })

  const hideError = () => {
    errorToast.value.message = ''
    errorToast.value.hidden = true
  }

  const showCloseRegisterError = () => {
    errorToast.value.message = 'DAILY_BALANCE.CLOSE_REGISTER_ERROR'
    errorToast.value.hidden = false
  }

  const showNotRequireBalancing = () => {
    errorToast.value.message = 'DAILY_BALANCE.DOES_NOT_REQUIRE_BALANCING'
    errorToast.value.hidden = false
  }

  const setMismatchError = () => {
    errorToast.value.hidden = true
    setTimeout(() => {
      errorToast.value.message = 'DAILY_BALANCE.MISMATCH_ERROR'
      errorToast.value.closable = true
      errorToast.value.hidden = false
    }, 300)
  }

  const handleCloseError = () => {
    errorToast.value.message = ''
    errorToast.value.hidden = true
    errorToast.value.closable = false
  }

  const handleCalculateError = (error) => {
    if (error !== '') {
      errorToast.value.message = error
      errorToast.value.closable = true
      errorToast.value.hidden = false
    } else {
      handleCloseError()
    }
  }

  const showEmptyCommentError = () => {
    errorToast.value.message = 'Please make sure to enter comment'
    errorToast.value.hidden = false
  }

  return {
    errorToast,
    hideError,
    showCloseRegisterError,
    showNotRequireBalancing,
    setMismatchError,
    handleCloseError,
    handleCalculateError,
    showEmptyCommentError
  }
}
