<template>
  <pn-modal :open="show" class="print-modal" @close="closeModal">
    <h2>{{ isLoading ? 'Preparing Print' : title }}</h2>

    <div>
      <pn-illustration :illustration="postagemeter_letters_plant" />
      <div class="text-content">
        <p v-show="!isLoading">
          Your document is now ready to be printed. Click on the link below to
          open the PDF file in a new tab.
        </p>
        <pn-toast :icon="alert_info_circle" :closable="false"
          ><p>
            Please disable any pop-up blockers on your browser or configure them
            to allow pop-ups from our website. If you face any issues, please
            check your browser settings.
          </p></pn-toast
        >
      </div>
    </div>

    <div class="actions">
      <pn-button :icon="arrow_right" :loading="isLoading" @click="open">
        Open PDF Print
      </pn-button>
    </div>
  </pn-modal>
</template>

<script>
import { alert_info_circle, arrow_right } from '@/shared/constants/icons.js'
import { postagemeter_letters_plant } from '@/shared/constants/illustrations.js'

export default {
  name: 'PrintOpenModal',
  props: {
    show: Boolean,
    title: {
      type: String,
      default() {
        return 'Your Print is Ready'
      }
    },
    url: {
      type: String,
      default() {
        return null
      }
    },
    isLoading: Boolean
  },
  emits: ['didClose'],
  data() {
    return {
      alert_info_circle,
      arrow_right,
      postagemeter_letters_plant
    }
  },
  methods: {
    closeModal() {
      this.$emit('didClose')
    },
    open() {
      window.open(this.url, '_blank')
      URL.revokeObjectURL(this.url)
      this.$emit('didClose')
    }
  }
}
</script>
<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: space-around;
  margin: 1em 0;
}

.text-content {
  padding: 1em;
}
</style>
