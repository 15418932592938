<template>
  <div class="phone-number-input" :class="hasError ? 'error' : ''">
    <div class="label-container">
      <PhoneNumberTooltip
        v-if="showTooltip"
        :key="hasError"
        :color="hasError ? 'warning' : 'blue'"
        class="helper-info"
      />
      <label>{{ label }}</label>
    </div>
    <VueTelInput
      v-model="draftValue"
      class="tel-input"
      mode="international"
      :class="{ disabled: disabled, isPlaceholder: draftValue === '' }"
      :placeholder="placeholder"
      :required="required"
      :preferred-countries="['SE', 'DK']"
      :disabled="disabled"
      :input-options="inputOptions"
      :dropdown-options="dropdownOptions"
      :valid-characters-only="true"
      @validate="handleValidation"
      @paste="handleOnPaste"
      @input="handleInput"
    />
    <small
      ><pn-icon
        v-if="hasError"
        :symbol="alert_exclamation_circle"
        small
        color="warning"
      />
      {{ helpertext }}</small
    >
  </div>
</template>

<script>
import { alert_exclamation_circle } from '@/shared/constants/icons.js'
import { validatePhoneNumber } from '@/shared/utils/stringUtils.js'
import { VueTelInput } from 'vue-tel-input'
import PhoneNumberTooltip from './PhoneNumberTooltip.vue'

export default {
  name: 'PhoneNumberInput',
  components: {
    VueTelInput,
    PhoneNumberTooltip
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: Boolean,
    disabled: Boolean,
    error: {
      type: String,
      default: ''
    },
    helpertext: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default() {
        return ''
      }
    },
    showTooltip: {
      type: Boolean,
      default: true
    },
    showDropdown: {
      type: Boolean,
      default: true
    }
  },
  emits: ['didUpdateValue', 'didValidate'],
  data() {
    return {
      draftValue: '',
      alert_exclamation_circle
    }
  },
  computed: {
    hasError() {
      return this.error !== ''
    },
    inputOptions() {
      return {
        showDialCode: true,
        maxlength: 18,
        placeholder: this.placeholder
      }
    },
    dropdownOptions() {
      return {
        disabled: !this.showDropdown,
        showDialCodeInList: true,
        showFlags: true
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.draftValue = val || ''
      }
    },
    draftValue(val) {
      this.$emit('didUpdateValue', val)
    }
  },
  methods: {
    handleValidation(event) {
      if (event.valid === undefined) {
        this.draftValue = ''
      } else {
        this.$emit('didValidate', event)
      }
    },
    handleOnPaste(event) {
      this.draftValue = ''
      const pasted = event.clipboardData.getData('text')
      if (!pasted.includes('+')) {
        this.draftValue = `+${this.draftValue}`
      }
    },
    handleInput() {
      const event = {}
      event.valid = validatePhoneNumber(this.draftValue)
      this.$emit('didValidate', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.phone-number-input {
  margin-bottom: 1em;

  .tel-input:focus-within {
    border: 0.1rem solid $blue700;
    -webkit-box-shadow: 0 0 0 0.2rem $white, 0 0 0 0.4rem $blue700;
    box-shadow: 0 0 0 0.2rem $white, 0 0 0 0.4rem $blue700;
  }
}

small {
  color: $gray700;
  pn-icon {
    margin-top: 0.35em;
  }
}
.label-container {
  margin-bottom: 0.4rem;
  color: $gray700;
  font-size: 0.875em;
  display: flex;
  align-items: center;

  .helper-info {
    margin-right: 4px;
  }
}

.tel-input {
  padding: 0.75em;
  -webkit-font-smoothing: antialiased;
  outline: none;
  border-radius: 0.8rem;
  border: 0.1rem solid $gray700;
  width: 100%;
  margin-bottom: 0.15em;
  transition: box-shadow 0.15s, border 0.15s, -webkit-box-shadow 0.15s;
}

.tel-input.disabled {
  background-color: $gray50;
  border: unset;
}

.phone-number-input.error {
  small {
    color: $warning;
  }

  .tel-input {
    border: 0.1rem solid $warning;
  }

  .label-container {
    color: $warning;
  }

  .tel-input:focus-within {
    border: 0.1rem solid $warning;
    -webkit-box-shadow: 0 0 0 0.2rem $white, 0 0 0 0.4rem $warning;
    box-shadow: 0 0 0 0.2rem $white, 0 0 0 0.4rem $warning;
  }
}

::v-deep(.pn-tooltip-content) {
  text-align: left !important;
  z-index: 3 !important;
}

::v-deep(.vti__dropdown) {
  padding: unset;
  padding-right: 8px;
}

::v-deep(.vti__input) {
  font-size: 1em;
  font-weight: 500;
  color: $black;
  padding-left: unset;
}

::v-deep(.isPlaceholder .vti__input) {
  font-weight: unset;
}

::v-deep(.vti__dropdown.disabled) {
  display: none;
}

::v-deep(.vti__dropdown-list) {
  z-index: 3;
}
</style>
