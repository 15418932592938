import { validatePhoneNumber } from '@/shared/utils/stringUtils.js'

import useValidators from '@/shared/composables/useValidators.js'

const validationRequiredError = 'CUSTOMER_MANAGEMENT.VALIDATION_REQUIRED'

export const customerName = {
  name: 'companyName',
  label: 'CUSTOMER_MANAGEMENT.NAME',
  required: true,
  validation: (val) => Boolean(val),
  error: validationRequiredError,
  component: 'pn-input'
}

export const section = {
  name: 'department',
  label: 'CUSTOMER_MANAGEMENT.SECTION',
  required: false,
  validation: () => true,
  error: validationRequiredError,
  component: 'pn-input'
}

export const customerNumber = {
  name: 'customerNumber',
  label: 'CUSTOMER_MANAGEMENT.CUSTOMER_NUMBER',
  required: true,
  validation: (val) => Boolean(val),
  error: validationRequiredError,
  component: 'pn-input'
}

export const boxAddresses = {
  name: 'boxAddresses',
  label: 'CUSTOMER_MANAGEMENT.BOX_ADDRESSES',
  required: true,
  validation: (val) => Boolean(val),
  error: validationRequiredError,
  helperText: 'CUSTOMER_MANAGEMENT.VALIDATION_BOX_ADDRESSES',
  component: 'pn-input'
}

export const addressLine2 = {
  name: 'streetAddress',
  label: 'CUSTOMER_MANAGEMENT.ADDRESS_LINE_2',
  required: false,
  validation: () => true,
  error: validationRequiredError,
  component: 'pn-input'
}

export const zip = {
  name: 'postalCode',
  label: 'CUSTOMER_MANAGEMENT.ZIP_CODE',
  required: true,
  validation: (val) => Boolean(val),
  error: validationRequiredError,
  component: 'pn-input'
}

export const city = {
  name: 'city',
  label: 'CUSTOMER_MANAGEMENT.CITY',
  required: true,
  validation: (val) => Boolean(val),
  error: validationRequiredError,
  component: 'pn-input'
}

export const emailAddress = {
  name: 'email',
  label: 'CUSTOMER_MANAGEMENT.EMAIL_ADDRESS',
  required: false,
  validation: (val) => !val || useValidators().isEmail(val),
  error: 'CUSTOMER_MANAGEMENT.VALIDATION_EMAIL',
  component: 'pn-input'
}

export const phoneNumber = {
  name: 'phoneNumber',
  label: 'CUSTOMER_MANAGEMENT.TELEPHONE',
  required: true,
  validation: (val) => !val || validatePhoneNumber(val),
  error: 'CUSTOMER_MANAGEMENT.VALIDATION_PHONE',
  helperText: 'CUSTOMER_MANAGEMENT.VALIDATION_PHONE',
  component: 'PhoneNumberInput'
}

export const mobilePhone = {
  name: 'mobilePhone',
  label: 'CUSTOMER_MANAGEMENT.MOBILE_PHONE',
  required: true,
  validation: (val) => !val || validatePhoneNumber(val),
  error: 'CUSTOMER_MANAGEMENT.VALIDATION_PHONE',
  helperText: 'CUSTOMER_MANAGEMENT.VALIDATION_PHONE',
  component: 'PhoneNumberInput'
}

export const customerFormDefaultState = {
  postalCode: '',
  city: '',
  boxAddresses: '',
  streetAddress: '',
  companyName: '',
  email: '',
  department: '',
  customerNumber: '',
  phoneNumber: '',
  mobilePhone: ''
}
