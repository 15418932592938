<template>
  <div>
    <h4>{{ readableDate1(content.date) }}</h4>
    <div class="day-forecast">
      <div class="day-forecast-content">
        <div class="day-forecast-left">
          <h4>{{ $t(content.title) }}</h4>
          <h1>{{ content.count }}</h1>
        </div>
        <img
          src="@/assets/svg/character-box-letters-house.svg"
          alt="character-box-letters-house"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { readableDate1 } from '@postnord/retail-backoffice-components/dist'

export default {
  name: 'DayForecast',
  props: {
    content: {
      type: Object,
      default() {
        return {
          title: 'FORECAST.EXPECTED_PARCELS',
          count: 0,
          date: ''
        }
      }
    }
  },
  methods: {
    readableDate1
  }
}
</script>

<style lang="scss" scoped>
.day-forecast {
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  text-align: center;
  min-width: 50%;
  height: 200px;
  color: $black;
  padding: 0 10px;
  background-size: cover;
  overflow: hidden;
  border-radius: 1.2rem;
  border: solid 1px $gray200;

  img {
    width: 300px;
    position: absolute;
    bottom: -25px;
    right: -30px;
  }
}
.day-forecast-left {
  text-align: left;
  h1 {
    color: $blue700;
    margin: unset;
  }
  h4 {
    margin: 0.5em 0;
  }
}

.day-forecast-content {
  display: flex;
  justify-content: space-between;
  padding: 0 2em;
  align-items: center;
  height: inherit;
  position: relative;
}

@media (max-width: 540px) {
  .day-forecast {
    img {
      width: 300px;
      position: absolute;
      right: -50px;
    }
  }
}
</style>
