<template>
  <PageContent :heading="heading">
    <EmailInputModal
      :title="$t('DAILY_BALANCE.SEND_PRINTOUT_TO_EMAIL')"
      :show="emailModal.show"
      @did-close="emailModal.show = false"
      @did-send="handleDidConfirmEmail"
    />
    <div class="daily-balance-content">
      <div class="main-control">
        <h3>{{ $t('DAILY_BALANCE.CHECK_REGISTER') }}</h3>
        <span>{{
          showCommentForm
            ? $t('DAILY_BALANCE.MISMATCH_GUIDE')
            : $t('DAILY_BALANCE.CHECK_REGISTER_DESCRIPTION')
        }}</span>
        <DailyBalanceLogs
          v-show="!errorToast.hidden"
          :error-toast="errorToast"
          @did-close="handleCloseError"
        />
        <div v-if="canDoBalancing">
          <DailyBalanceForm
            v-show="!showCommentForm"
            :disabled="!isCashRegisterOpen || loading"
            @did-update-amount="balanceDraft = $event"
            @did-calculate-with-error="handleCalculateError"
          />
          <CommentForm
            v-if="showCommentForm"
            :is-loading="loading"
            @send-comment="handleSendComment"
            @send-email="handleWillSendEmail"
            @comment-with-error="showEmptyCommentError"
          />
        </div>
        <div v-show="showResendEmail">
          <p>{{ $t('DAILY_BALANCE.DID_FAIL_EMAIL_PRINTOUT') }}</p>
          <pn-button appearance="light" :icon="rotate" @click="resendEmail">
            {{ $t('DAILY_BALANCE.SEND_AGAIN') }}</pn-button
          >
        </div>
        <Loader v-show="loading" />
      </div>
      <DailyBalanceDetails
        class="details"
        :show-confirm="canDoBalancing && !showCommentForm"
        :amount="balanceDraft"
        :expected-amount="balanceValue"
        :date="accountDay"
        :is-loading="loading"
        @confirm="handleConfirm"
      />
    </div>
  </PageContent>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import EmailInputModal from '@/shared/components/EmailInputModal.vue'
import PageContent from '@/shared/components/PageContent.vue'

import CommentForm from './components/CommentForm.vue'
import DailyBalanceDetails from './components/DailyBalanceDetails.vue'
import DailyBalanceForm from './components/DailyBalanceForm.vue'
import DailyBalanceLogs from './components/DailyBalanceLogs.vue'

import useEmailHandler from '@/shared/composables/useEmailHandler.js'
import usePrint from '@/shared/composables/usePrint.js'
import {
  alert_exclamation_circle,
  check,
  rotate
} from '@/shared/constants/icons.js'
import useCommenting from './composables/useCommenting.js'
import useErrorToast from './composables/useErrorToast.js'

export const removedLast2Digit = (amount) =>
  parseInt(`${amount}`.slice(0, -2)) * 1

export default {
  name: 'DailyBalance',
  components: {
    PageContent,
    DailyBalanceForm,
    DailyBalanceDetails,
    DailyBalanceLogs,
    EmailInputModal,
    CommentForm
  },
  setup() {
    const {
      errorToast,
      hideError,
      showCloseRegisterError,
      showNotRequireBalancing,
      setMismatchError,
      handleCloseError,
      handleCalculateError,
      showEmptyCommentError
    } = useErrorToast()
    const { emailModal, shouldEmail, showResendEmail } = useEmailHandler()
    const { commentDraft, confirmButtonCounter, maxConfirmClicks } =
      useCommenting()
    const { printPdf } = usePrint()
    return {
      errorToast,
      hideError,
      showCloseRegisterError,
      showNotRequireBalancing,
      setMismatchError,
      handleCloseError,
      handleCalculateError,
      showEmptyCommentError,
      emailModal,
      shouldEmail,
      showResendEmail,
      commentDraft,
      confirmButtonCounter,
      maxConfirmClicks,
      printPdf
    }
  },
  data() {
    return {
      successToast: {
        showClose: true,
        icon: check,
        appearance: 'success',
        message: 'Daily balance confirmed',
        show: true
      },
      balanceDraft: 0,
      heading: {
        title: 'DAILY_BALANCE.TITLE',
        description: 'DAILY_BALANCE.DESCRIPTION',
        useTranslation: true
      },
      deviationThreshold: 50,
      rotate
    }
  },
  computed: {
    ...mapGetters('cashRegister', [
      'cashRegisterStatus',
      'accountDay',
      'balanceValue',
      'requiresBalancing',
      'isCashRegisterOpen'
    ]),
    ...mapState(['loading']),
    payloadForBalancing() {
      return {
        givenBalance: {
          currency: 'SEK',
          value: this.balanceDraft * 1 // This will parse the value as int or float
        },
        deviationReason: this.commentDraft
      }
    },
    canDoBalancing() {
      return this.isCashRegisterOpen && this.requiresBalancing
    },
    isBeyondThreshold() {
      const difference = Math.abs(
        removedLast2Digit(this.balanceDraft) -
          removedLast2Digit(this.balanceValue)
      )
      return difference > this.deviationThreshold
    },
    showCommentForm() {
      return (
        this.isBeyondThreshold &&
        this.confirmButtonCounter >= this.maxConfirmClicks
      )
    }
  },
  watch: {
    isCashRegisterOpen: {
      immediate: true,
      handler() {
        this.getInitialErrorState()
      }
    },
    requiresBalancing: {
      immediate: true,
      handler() {
        this.getInitialErrorState()
      }
    }
  },
  mounted() {
    this.subscribeMutations()
    this.getStatus()
  },
  methods: {
    ...mapActions('cashRegister', ['setBalance', 'getStatus']),
    ...mapActions('retail', ['getPdf']),
    ...mapActions('toaster', ['showToaster']),
    subscribeMutations() {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'cashRegister/didSetBalance') {
          if (this.commentDraft === '') {
            this.resetLocalState()
            this.showSuccessState()
          } else {
            // If balance endpoint was called with deviation reason
            // you'll get a value for deviationReportId
            // you can use this to call print endpoint for printing with PDF
            const printId = mutation.payload?.deviationReportId
            if (printId) {
              this.getPdf(printId)
            }
          }
        } else if (mutation.type === 'retail/didGetPdf') {
          const pdfData = mutation?.payload?.data
          this.handlePdfData(pdfData)
        }
      })
    },
    handleConfirm() {
      this.confirmButtonCounter++
      const localBalance = parseInt(this.balanceDraft)
      if (localBalance === this.balanceValue || !this.isBeyondThreshold) {
        this.setBalance(this.payloadForBalancing)
      } else {
        this.setMismatchError()
      }
    },
    handleSendComment(comment) {
      this.commentDraft = comment
      this.setBalance(this.payloadForBalancing)
    },
    handleWillSendEmail(comment) {
      this.commentDraft = comment
      this.shouldEmail = true
      this.emailModal.show = true
    },
    getPayloadWithEmail(email) {
      return {
        ...this.payloadForBalancing,
        reportRecipientEmail: email
      }
    },
    handleDidConfirmEmail(email) {
      this.emailModal.show = false
      this.emailModal.email = email
      const payload = this.getPayloadWithEmail(email)
      this.setBalance(payload)
    },
    resendEmail() {
      const payload = this.getPayloadWithEmail(this.emailModal.email)
      this.setBalance(payload)
    },
    handlePdfData(pdfData) {
      if (pdfData) {
        if (this.shouldEmail) {
          this.showDidReportState(this.emailModal)
        } else {
          this.showDidPrintState(pdfData)
        }
      } else {
        this.showPdfError()
      }
    },
    resetLocalState() {
      this.confirmButtonCounter = 0
      this.commentDraft = ''
      this.shouldEmail = false
      this.balanceDraft = 0
      const inputAmount = document.getElementById('daily-balance-main-input')
      if (inputAmount) {
        inputAmount.value = 0
      }
    },
    showSuccessState() {
      this.showToaster(this.successToast)
      this.hideError()
      this.getStatus()
    },
    showDidReportState(report) {
      // this.showResendEmail = true
      this.successToast.message = `Comment sent to ${report.email}`
      this.showSuccessState()
    },
    showDidPrintState(pdfData) {
      if (!window['Cypress']) {
        // Don't open when running e2e tests
        this.printPdf(pdfData)
      }
      this.successToast.message = 'Comment sent and report printed'
      this.showSuccessState()
      this.resetLocalState()
    },
    showPdfError() {
      const pdfError = {
        showClose: true,
        icon: alert_exclamation_circle,
        appearance: 'warning',
        message: 'Unable to generate report data.',
        show: true
      }
      this.showToaster(pdfError)
    },
    getInitialErrorState() {
      if (this.isCashRegisterOpen) {
        if (!this.requiresBalancing) {
          this.showNotRequireBalancing()
        } else {
          this.hideError()
        }
      } else {
        this.showCloseRegisterError()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.daily-balance-content {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: baseline;
  justify-content: space-around;
}

.main-control {
  flex: 1.5;
  padding: 0 2em;
  min-height: 500px;
  border-right: solid 2px $gray50;
}

.details {
  flex: 0.5;
  padding: 0 2em;
}

@media (max-width: 1125px) {
  .main-control {
    width: 100%;
    border-bottom: solid 2px $gray50;
    border-right: unset;
  }

  .details {
    padding-top: 2em;
  }
}
</style>
