<template>
  <div class="content">
    <div class="details-content">
      <h4>{{ $t('DAILY_BALANCE.DETAILS') }}</h4>
      <p class="label1">{{ $t('DAILY_BALANCE.TITLE') }}</p>
      <p>{{ date }}</p>
      <div class="total">
        <p class="label1">{{ $t('DAILY_BALANCE.EXPECTED_AMOUNT') }}</p>
        <p class="total-amount">
          {{ getNordicCurrencyFormat(expectedAmount) }} SEK
        </p>
      </div>
      <div class="total">
        <p class="label1">
          {{ $t('DAILY_BALANCE.AMOUNT') }}
        </p>
        <p class="total-amount" :class="{ matched: isMatched }">
          {{ getNordicCurrencyFormat(amount) }} SEK
        </p>
      </div>
    </div>
    <div v-show="showConfirm" class="actions">
      <pn-button :icon="check" :loading="isLoading" @click="confirm">{{
        $t('APP.CONFIRM')
      }}</pn-button>
    </div>
  </div>
</template>

<script>
import { check } from '@/shared/constants/icons.js'
import { getNordicCurrencyFormat } from '@/shared/utils/stringUtils.js'

export default {
  name: 'DailyBalanceDetails',
  props: {
    amount: {
      type: Number,
      default() {
        return 0
      }
    },
    showConfirm: Boolean,
    date: {
      type: String,
      default() {
        return '0000-00-00'
      }
    },
    expectedAmount: {
      type: Number,
      default() {
        return 0
      }
    },
    isLoading: Boolean
  },
  emits: ['confirm'],
  data() {
    return {
      check
    }
  },
  computed: {
    isMatched() {
      return this.amount === this.expectedAmount
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm', this.amount)
    },
    getNordicCurrencyFormat
  }
}
</script>

<style lang="scss" scoped>
.content {
  min-width: 25%;
  min-height: 80vh;
  position: relative;
}
.details-content {
  padding: 1em;
  background-color: $blue25;
  border-radius: 8px;

  h4 {
    padding-bottom: 1em;
  }
}
.actions {
  margin-top: 8em;
  pn-button {
    width: 100%;
  }
}

.total {
  margin-top: 1em;
  padding-top: 1em;
  border-top: solid 1px $blue200;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.matched {
  font-weight: 500;
  color: $green700;
}

@media (max-width: 1125px) {
  .content {
    min-width: 100%;
  }
}
</style>
