import { computed, reactive } from 'vue'

export default function useSearchFilters() {
  const filters = reactive({
    id: '',
    agreementId: '',
    dateFrom: '',
    dateTo: ''
  })

  const cleanFilters = computed(() => {
    return Object.entries(filters).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
  })

  const clearFilterState = () => {
    filters.id = ''
    filters.agreementId = ''
    filters.dateFrom = ''
    filters.dateTo = ''
  }

  return {
    filters,
    cleanFilters,
    clearFilterState
  }
}
