<template>
  <div v-if="!isEmpty">
    <ItemHeaders
      :headers="headers"
      :use-translation="true"
      :extra-columns="1"
      @sort="handleSort($event)"
    />
    <div v-for="(item, index) in visibleItems" :key="index" class="item-row">
      <div class="column list-id extend">
        <CheckBox
          :value="item.deliveryListId"
          :checked="draftSelectedIds.includes(item.deliveryListId)"
          :disabled="item.state === 'delivered'"
          @select="manageSelectedIds(item.deliveryListId)"
        >
          <template #component>
            <text-highlight
              highlight-class-name="highlight"
              :search-words="[searchText]"
              :auto-escape="true"
              :text-to-highlight="
                item.deliveryListId ? item.deliveryListId : ''
              "
            />
            -
            <span>
              <text-highlight
                highlight-class-name="highlight"
                :search-words="[searchText]"
                :auto-escape="true"
                :text-to-highlight="
                  item.agreementName ? item.agreementName : ''
                "
              />
            </span>
          </template>
        </CheckBox>
      </div>
      <div class="column">
        <span>{{ item.created }}</span>
      </div>
      <div class="column">
        <span>{{ item.items.length }}</span>
      </div>
      <div class="column">
        <DeliveryListStatus :status="item.state" />
      </div>
      <div class="column actions">
        <pn-button
          appearance="light"
          :icon="arrow_right"
          small
          @click="selectDeliveryList(item)"
        >
          {{ $t('APP.DETAILS') }}
        </pn-button>
      </div>
    </div>
    <NavPagination
      v-if="filteredItems.length"
      :lists="filteredItems"
      :per-page="10"
      :total="filteredItems.length"
      @page-changed="updatePage"
    />
  </div>
  <EmptyContent v-else />
</template>

<script>
import CheckBox from '@/shared/components/CheckBox.vue'
import ItemHeaders from '@/shared/components/ItemHeaders.vue'
import NavPagination from '@/shared/components/NavPagination.vue'
import { arrow_right } from '@/shared/constants/icons.js'
import genericListMixin from '@/shared/mixins/genericListMixin.js'
import TextHighlight from 'vue-highlight-words'
import DeliveryListStatus from './DeliveryListStatus.vue'

export default {
  name: 'DeliveryListsTable',
  components: {
    TextHighlight,
    ItemHeaders,
    NavPagination,
    DeliveryListStatus,
    CheckBox
  },
  mixins: [genericListMixin],
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    searchText: {
      type: String,
      default() {
        return ''
      }
    },
    selectedIds: {
      type: Array,
      default() {
        return []
      }
    }
  },
  emits: ['didSelect', 'didUpdateSelectedItems'],
  data() {
    return {
      headers: [
        {
          label: 'DELIVERY_LIST.ID',
          isSortable: true,
          isExtended: true,
          sortKey: 'deliveryListId'
        },
        {
          label: 'APP.DATE',
          isSortable: true,
          sortKey: 'created'
        },
        {
          label: 'DELIVERY_LIST.ITEMS',
          isSortable: true,
          sortKey: 'items'
        },
        {
          label: 'APP.STATUS',
          isSortable: true,
          sortKey: 'state'
        }
      ],
      draftSelectedIds: [],
      arrow_right
    }
  },
  computed: {
    isEmpty() {
      return this.items.length === 0
    }
  },
  watch: {
    items: {
      immediate: true,
      handler(val) {
        if (val) {
          this.mainItems = val
        }
      }
    },
    searchText(val) {
      this.filterByQuery(val)
    },
    selectedIds(val) {
      this.draftSelectedIds = [...val]
    }
  },
  methods: {
    selectDeliveryList(item) {
      this.$emit('didSelect', item)
    },
    handleSort(event) {
      if (event === 'items') {
        const sorter = (a, b) => {
          const modifier = this.sortDirection === 'desc' ? -1 : 1
          return a[event].length < b[event].length
            ? -1 * modifier
            : 1 * modifier
        }
        this.mainItems = this.sorted(event, this.mainItems, sorter)
      } else {
        this.sortByProperty(event)
      }
    },
    manageSelectedIds(id) {
      if (this.draftSelectedIds.includes(id)) {
        this.draftSelectedIds = this.draftSelectedIds.filter(
          (item) => item !== id
        )
      } else {
        this.draftSelectedIds = [...this.draftSelectedIds, id]
      }
      this.$emit('didUpdateSelectedItems', this.draftSelectedIds)
    }
  }
}
</script>

<style lang="scss" scoped>
.column {
  flex: 1;
}

.column.checkbox {
  max-width: 5%;
}

.column.extend {
  flex: 1.7;
}

.list-id {
  font-weight: 500;
  cursor: pointer;
}

.actions {
  justify-content: flex-end;
  align-items: center;
}
.item-row {
  a {
    text-decoration: none;
  }
}

@media (max-width: 700px) {
  .column {
    flex: unset;
  }
  .actions {
    margin-top: 1em;
    width: 100%;
    pn-button {
      width: 50%;
    }
  }
}

@media (max-width: $breakpoint-phone) {
  .item-row .column {
    width: 100%;
    margin-bottom: 4px;
  }
}
</style>
