import { sortableMixin } from '@postnord/retail-backoffice-components/dist'

export default {
  mixins: [sortableMixin],
  data() {
    return {
      /**
       * is the main items to set, if you have a store state list used,
       * you also need to set this, since this will be the main reference
       * in filtering */
      mainItems: [],
      filteredItems: [],
      visibleItems: [],
      perPage: 10
    }
  },
  watch: {
    mainItems(val) {
      this.filteredItems = val
    }
  },
  methods: {
    sortByProperty(key) {
      this.filteredItems = this.sorted(key, this.mainItems)
    },
    filterByQuery(query) {
      this.filteredItems = this.mainItems.filter((item) => {
        return JSON.stringify(item).toLowerCase().includes(query.toLowerCase())
      })
    },
    updatePage(e) {
      this.visibleItems = e
    }
  }
}
