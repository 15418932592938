import { alert_exclamation_circle } from '@/shared/constants/icons.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      expiryIntervalId: null
    }
  },
  computed: {
    ...mapGetters('retail', {
      isRetailTokenExpired: 'isTokenExpired',
      isRetailLoggedIn: 'isLoggedIn',
      retailDecodedIdToken: 'decodedIdToken'
    }),
    ...mapGetters('unified', {
      isUnifiedTokenExpired: 'isTokenExpired',
      isUnifiedLoggedIn: 'isLoggedIn',
      unifiedDecodedIdToken: 'decodedIdToken',
      fullName: 'fullName'
    }),
    userInfo() {
      if (this.isRetailLoggedIn) {
        return this.retailDecodedIdToken
      } else {
        return this.unifiedDecodedIdToken
      }
    },
    isRetailShouldLogout() {
      return this.isRetailLoggedIn && this.isRetailTokenExpired
    },
    isUnifiedShouldLogout() {
      return this.isUnifiedLoggedIn && this.isUnifiedTokenExpired
    },
    isLoggedIn() {
      return this.isRetailLoggedIn || this.isUnifiedLoggedIn
    },
    displayName() {
      if (this.isUnifiedLoggedIn) {
        return this.fullName
      } else if (this.isRetailLoggedIn) {
        return this.retailDecodedIdToken?.id
      }
    },
    shouldLogout() {
      return this.isRetailShouldLogout || this.isUnifiedShouldLogout
    },
    expiry() {
      let expiryTime = null
      if (this.isRetailLoggedIn && this.retailDecodedIdToken) {
        expiryTime = this.retailDecodedIdToken.exp
      } else if (this.isUnifiedLoggedIn && this.unifiedDecodedIdToken) {
        expiryTime = this.unifiedDecodedIdToken.exp
      }
      return expiryTime
    }
  },
  watch: {
    isRetailLoggedIn: {
      immediate: true,
      handler(val) {
        if (val) {
          this.hideUnifiedTopbar()
          this.getCapability()
        }
      }
    },
    isUnifiedLoggedIn: {
      immediate: true,
      async handler(val) {
        if (val && !this.isRetailLoggedIn) {
          this.setUnifiedStoreState()
          await this.growthbookInit()
          await this.getPermission()
        }
      }
    },
    isRetailShouldLogout: {
      immediate: true,
      handler(val) {
        if (val) {
          this.handleAutoLogout()
        }
      }
    },
    isUnifiedShouldLogout: {
      immediate: true,
      handler(val) {
        if (val) {
          this.handleAutoLogout()
        }
      }
    },
    expiry: {
      immediate: true,
      handler() {
        this.startExpiryInterval()
      }
    }
  },
  mounted() {
    if (this.shouldLogout) {
      this.handleAutoLogout()
    } else {
      if (window.pnTopbar?.hasLoaded) {
        this.checkIfUnifiedLoggedIn()
      } else {
        window.addEventListener('topbarLoaded', () =>
          this.checkIfUnifiedLoggedIn()
        )
      }
    }
  },
  unmounted() {
    window.removeEventListener('topbarLoaded', () =>
      this.checkIfUnifiedLoggedIn()
    )
  },
  methods: {
    ...mapActions('retail', {
      logoutUsingRetail: 'logout',
      getCapability: 'getCapability'
    }),
    ...mapActions('unified', {
      loginUsingUnified: 'login',
      logoutUsingUnified: 'logout',
      getPermission: 'getPermission',
      setUnifiedStoreState: 'setUnifiedStoreState'
    }),
    ...mapActions('features', ['growthbookInit']),
    handleAutoLogout() {
      this.showIsExpiredToast()
      this.handleLogout()
    },
    handleLogout() {
      this.showConfirmModal = false
      if (this.isRetailLoggedIn) {
        this.logoutUsingRetail()
        localStorage.removeItem('TMP_ACTIVE_SP')
        window.location.reload()
      }
      this.logoutUsingUnified()
    },
    showIsExpiredToast() {
      const toast = {
        closable: true,
        icon: alert_exclamation_circle,
        message: this.$i18n.t('APP.SESSION_EXPIRED')
      }
      this.showToaster(toast)
    },
    hideUnifiedTopbar() {
      const topbarContainer = document.getElementById('postnordTopbarContainer')
      if (topbarContainer) {
        topbarContainer.style.display = 'none'
      }
    },
    checkIfUnifiedLoggedIn() {
      if (this.isUnifiedLoggedIn || window.pnTopbar.isLoggedIn) {
        this.setUnifiedStoreState()
      }
      this.isLoading = false
    },
    startExpiryInterval() {
      if (this.expiry) {
        if (this.expiryIntervalId) {
          clearInterval(this.expiryIntervalId)
        }
        this.expiryIntervalId = setInterval(this.checkExpiry, 1000)
      }
    },
    checkExpiry() {
      const currentTime = Date.now() / 1000
      if (currentTime >= this.expiry) {
        clearInterval(this.expiryIntervalId)
        this.handleAutoLogout()
      }
    }
  }
}
