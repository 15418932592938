<template>
  <div class="main-content">
    <div class="flex-group">
      <pn-input
        id="daily-balance-main-input"
        :key="componentKey"
        type="number"
        class="main-input"
        :label="$t('DAILY_BALANCE.AMOUNT_OF_CASH')"
        :value="amount"
        :disabled="disabled"
        @keydown="handleKeydown"
        @input="amount = $event.target.value"
        @paste="handleOnPaste"
      ></pn-input>
      <pn-button
        appearance="light"
        variant="borderless"
        :icon="showCalculator ? angle_small_up : angle_small_down"
        @click="showCalculator = !showCalculator"
      >
        {{ $t('DAILY_BALANCE.CALCULATION_SUPPORT') }}
      </pn-button>
    </div>
    <DailyBalanceCalculator
      v-show="showCalculator"
      :disabled="disabled"
      @did-calculate="amount = $event"
      @did-calculate-with-error="handleCalculateError"
    />
  </div>
</template>

<script>
import { angle_small_down, angle_small_up } from '@/shared/constants/icons.js'
import DailyBalanceCalculator from './DailyBalanceCalculator.vue'

export default {
  name: 'DailyBalanceForm',
  components: {
    DailyBalanceCalculator
  },
  props: {
    disabled: Boolean
  },
  emits: ['didUpdateAmount', 'didCalculateWithError'],
  data() {
    return {
      amount: null,
      showCalculator: true,
      componentKey: 0,
      angle_small_up,
      angle_small_down
    }
  },
  watch: {
    amount(val) {
      this.$emit('didUpdateAmount', parseInt(`${val}00`))
    }
  },
  methods: {
    handleCalculateError(error) {
      this.$emit('didCalculateWithError', error)
    },
    handleKeydown(event) {
      if (event.key === 'e' || event.key === 'E' || event.key === '.') {
        event.preventDefault()
      }
    },
    handleOnPaste(event) {
      const pasted = event.clipboardData.getData('text')
      this.amount = parseInt(pasted)
      this.componentKey += 1 // force rerender input to latest amount value
    }
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  margin: 1em 0;
}

.main-input {
  width: 50%;
}
</style>
