<template>
  <div class="logs-display">
    <pn-toast
      appearance="warning"
      :icon="alert_exclamation_triangle"
      :hidden="errorToast.hidden"
      :closable="`${errorToast.closable}`"
      @close="handleClose"
    >
      {{ $t(errorToast.message) }}
    </pn-toast>
  </div>
</template>

<script>
import { alert_exclamation_triangle } from '@/shared/constants/icons.js'

export default {
  name: 'DailyBalanceLogs',
  props: {
    errorToast: {
      type: Object,
      default() {
        return {
          hidden: true,
          message: '',
          closable: false
        }
      }
    }
  },
  emits: ['didClose'],
  data() {
    return {
      alert_exclamation_triangle
    }
  },
  methods: {
    handleClose() {
      this.$emit('didClose')
    }
  }
}
</script>

<style lang="scss" scoped>
.logs-display {
  margin: 2em 0;

  pn-toast {
    width: 100%;
  }
}
</style>
