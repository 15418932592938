<template>
  <div class="content">
    <pn-toast :appearance="isUp ? 'success' : 'warning'" closable="false">
      {{ isUp ? $t('FORECAST.SYSTEM_UP') : $t('FORECAST.SYSTEM_DOWN') }}
    </pn-toast>
  </div>
</template>

<script>
import forecastService from '../services/forecastService.js'

export default {
  name: 'ForecastHealth',
  props: {
    shouldCheck: Boolean
  },
  emits: ['didChangeStatus'],
  data() {
    return {
      isUp: true,
      isLoading: false
    }
  },
  watch: {
    shouldCheck(val) {
      if (val) {
        this.getHealth()
      }
    }
  },
  mounted() {
    this.getHealth()
  },
  methods: {
    getHealth: async function () {
      this.isLoading = true
      try {
        const response = await forecastService.getHealth()
        this.isUp = response?.data?.status.toUpperCase() === 'UP'
        this.$emit('didChangeStatus', this.isUp)
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  margin: auto;
}
</style>
