<template>
  <div class="content">
    <nav role="navigation" aria-label="pagination">
      <ul class="pagination">
        <li>
          <button
            v-if="page != 1"
            type="button"
            class="page-item prev"
            @click="page--"
          >
            <pn-icon :symbol="arrow_left" small="false" color="blue700" />
          </button>
        </li>
        <li v-if="!slicedPages.includes(1)">
          <button
            type="button"
            class="page-item pagination-link"
            @click="page = 1"
          >
            1
          </button>
          <pn-icon
            class="page-item extra"
            :symbol="ellipsis_h"
            small="false"
            color="gray700"
          />
        </li>
        <li>
          <button
            v-for="pageNumber in slicedPages"
            :key="pageNumber"
            type="button"
            class="page-item pagination-link"
            :class="[page === pageNumber ? 'active-page' : '']"
            @click="page = pageNumber"
          >
            {{ pageNumber }}
          </button>
        </li>
        <li v-if="!slicedPages.includes(lastPage)">
          <pn-icon
            class="page-item extra"
            :symbol="ellipsis_h"
            small="false"
            color="gray700"
          />
          <button
            type="button"
            class="page-item pagination-link"
            @click="page = lastPage"
          >
            {{ lastPage }}
          </button>
        </li>
        <li>
          <button
            v-if="page < pages.length"
            type="button"
            class="page-item next"
            @click="page++"
          >
            <pn-icon :symbol="arrow_right" small="false" color="blue700" />
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {
  arrow_left,
  arrow_right,
  ellipsis_h
} from '@/shared/constants/icons.js'

export default {
  name: 'NavPagination',
  props: {
    lists: {
      type: Array,
      default() {
        return []
      }
    },
    perPage: {
      type: Number,
      default() {
        return 10
      }
    },
    total: {
      type: Number,
      default() {
        return 0
      }
    },
    icon: {
      type: String,
      default() {
        return 'box-label'
      }
    },
    totalVisiblePages: {
      type: Number,
      default() {
        return 3
      }
    }
  },
  emits: ['pageChanged'],
  data() {
    return {
      pages: [],
      page: 1,
      arrow_left,
      arrow_right,
      ellipsis_h
    }
  },
  computed: {
    slicedPages() {
      return this.pages.slice(
        this.page - 1,
        this.page + (this.totalVisiblePages - 1)
      )
    },
    lastPage() {
      return this.pages[this.pages.length - 1] || null
    }
  },
  watch: {
    page: function () {
      this.$emit('pageChanged', this.paginate())
    },
    lists: function () {
      // when new lists is set, it should reset the navigation
      // display also that's why we go back to page 1 as well
      this.pages = []
      this.setPages()
      this.page = 1
      this.$emit('pageChanged', this.paginate())
    }
  },
  mounted() {
    this.setPages()
    this.$emit('pageChanged', this.paginate())
  },
  methods: {
    setPages() {
      if (this.lists) {
        const numberOfPages = Math.ceil(this.lists.length / this.perPage)
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index)
        }
      }
    },
    paginate() {
      const page = this.page
      const perPage = this.perPage
      const from = page * perPage - perPage
      const to = page * perPage
      return this.lists?.slice(from, to)
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  align-items: center;
  justify-content: space-between;
}
ul.pagination {
  justify-content: center;
  list-style: none;
  display: flex;
}
.active-page {
  color: $white !important;
  background: $blue700 !important;
}
.page-item {
  border-radius: 50%;
  width: 2.4em;
  height: 2.4em;
  padding: 4px;
  margin: 0.2em;
  font-size: 90%;
  background: $white;
  border: 2px solid $blue700;
  color: $blue700;
  cursor: pointer;
}
.page-item.extra {
  border: unset;
  cursor: unset;
}
</style>
