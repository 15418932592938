<template>
  <div class="content">
    <EmptyContent
      :illustration="discover"
      :title="$t('APP.PAGE_NOT_FOUND')"
      :description="$t('APP.PAGE_NOT_FOUND_DESCRIPTION')"
    />
    <HomeButton />
  </div>
</template>
<script>
import HomeButton from '@/shared/components/HomeButton.vue'
import { discover } from '@/shared/constants/illustrations.js'
export default {
  name: 'PageNotFound',
  components: { HomeButton },
  data() {
    return {
      discover
    }
  }
}
</script>
<style scoped lang="scss">
.content {
  text-align: center;
  padding: 2em;
  min-height: 500px;
}
</style>
