<template>
  <div>
    <ItemHeaders
      :headers="headers"
      :use-translation="true"
      :extra-columns="1"
      @sort="sortByProperty($event)"
    />
    <div v-for="(item, index) in visibleItems" :key="index" class="item-row">
      <div class="column">
        <span class="highlighted-label">
          <text-highlight
            highlight-class-name="highlight"
            :search-words="[searchText]"
            :auto-escape="true"
            :text-to-highlight="item.itemId ? item.itemId : ''"
          />
        </span>
      </div>
      <div class="column">{{ item.itemState ? item.itemState : '' }}</div>
      <div class="column">
        <span v-if="item.shelfNo" class="status pn-m">
          {{ item.shelfNo }}
        </span>
      </div>
      <div class="column">
        {{ item.sender ? item.sender.name : '' }}
      </div>
      <div class="column">
        {{ item.arrivalDate }}
      </div>
      <div class="column">
        {{ item.returnDate }}
      </div>
      <div class="column">
        {{ item.product ? item.product.name : '' }}
      </div>
      <div class="column actions">
        <pn-button
          v-if="isItemPrintable(item)"
          :icon="printIcon"
          class="print-button"
          appearance="light"
          left-icon
          small
          :loading="loading"
          @click="print(item)"
        >
          {{ $t('APP.PRINT') }}
        </pn-button>
      </div>
    </div>
    <NavPagination
      v-if="filteredItems.length"
      :lists="filteredItems"
      :per-page="10"
      :total="filteredItems.length"
      @page-changed="updatePage"
    />
  </div>
</template>

<script>
import ItemHeaders from '@/shared/components/ItemHeaders.vue'
import NavPagination from '@/shared/components/NavPagination.vue'
import { print as printIcon } from '@/shared/constants/icons.js'
import genericListMixin from '@/shared/mixins/genericListMixin.js'
import TextHighlight from 'vue-highlight-words'
import { mapState } from 'vuex'

export default {
  name: 'ArrivedItemsTable',
  components: {
    ItemHeaders,
    NavPagination,
    TextHighlight
  },
  mixins: [genericListMixin],
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    searchText: {
      type: String,
      default() {
        return ''
      }
    }
  },
  emits: ['willPrintItem'],
  data() {
    return {
      headers: [
        { label: 'FALLBACK.ITEM_ID', isSortable: true, sortKey: 'itemId' },
        { label: 'FALLBACK.ITEM_STATE', isSortable: false },
        { label: 'FALLBACK.SHELF_NO', isSortable: true, sortKey: 'shelfNo' },
        { label: 'FALLBACK.SENDER', isSortable: false },
        {
          label: 'FALLBACK.ARRIVAL_DATE',
          isSortable: true,
          sortKey: 'arrivalDate'
        },
        { label: 'FALLBACK.RETURN_DATE', isSortable: false },
        { label: 'FALLBACK.PRODUCT_NAME', isSortable: false }
      ],
      printIcon
    }
  },
  computed: {
    ...mapState(['loading']),
    mappedItems() {
      if (this.items) {
        return this.items.map((item) => {
          item.arrivalDate = this.getCleanDate(item.arrivalDate)
          item.returnDate = this.getCleanDate(item.returnDate)
          return item
        })
      }
      return []
    }
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        this.mainItems = this.mappedItems
      }
    },
    searchText: {
      immediate: true,
      handler(val) {
        this.filterByQuery(val)
      }
    }
  },
  methods: {
    getCleanDate(date) {
      let displayDate = ''
      if (date !== undefined || date) {
        const dateObj = new Date(date)
        displayDate = `${dateObj.toLocaleDateString(
          'sv-SE'
        )} ${dateObj.toLocaleTimeString('sv-SE')}`
      }
      return displayDate
    },
    isItemPrintable(item) {
      return item?.itemId?.startsWith('RP')
    },
    print(item) {
      this.$emit('willPrintItem', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.item-row:not(.header) {
  padding: 1em;
}
.column {
  flex: 1;
  min-width: 10%;
  max-width: 14%;
  overflow-wrap: break-word;
}
.actions {
  justify-content: flex-end;
}
.status {
  background: $blue400;
  border-radius: 24px;
  color: #f2fafd;
  padding: 8px;
  margin: 4px;
  font-weight: 500;
}
@media (max-width: 560px) {
  .status {
    font-size: 90%;
    margin: unset;
  }
}

@media (max-width: $breakpoint-phone) {
  .column {
    flex: unset;
    max-width: unset;
    width: 100%;
    margin-bottom: 12px;
  }
}
</style>
