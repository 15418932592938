<template>
  <pn-button left-icon :icon="arrow_left" @click="gotoHomePage">{{
    $t('APP.GO_TO_HOME')
  }}</pn-button>
</template>

<script>
import { arrow_left } from '@/shared/constants/icons.js'

export default {
  name: 'HomeButton',
  data() {
    return {
      arrow_left
    }
  },
  methods: {
    gotoHomePage() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style scoped lang="scss">
pn-button {
  margin: 1em 0;
}
</style>
