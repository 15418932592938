<template>
  <div class="field zip">
    <pn-input
      :name="zipField.name"
      :label="$t(zipField.label)"
      :placeholder="$t(zipField.label)"
      :required="zipField.required"
      :error="
        isClean || zipField.validation(draftPostalCode)
          ? ''
          : $t(zipField.error)
      "
      :value="draftPostalCode"
      :disabled="disabled"
      @input="draftPostalCode = $event.target.value"
    />
    <pn-input
      :name="cityField.name"
      :label="cityLabel"
      :placeholder="$t(cityField.label)"
      :required="cityField.required"
      :error="
        isClean || cityField.validation(draftCity) ? '' : $t(cityField.error)
      "
      :value="draftCity"
      :disabled="disabled"
      @input="draftCity = $event.target.value"
    />
  </div>
</template>

<script>
import postalcodeService from '@/shared/services/postalcodeService.js'
import { city as cityField, zip as zipField } from '../constants/formFields.js'

export default {
  name: 'PostalCodeCityInput',
  props: {
    countryCode: {
      type: String,
      default: 'SE'
    },
    postalCode: {
      type: String,
      default() {
        return ''
      }
    },
    city: {
      type: String,
      default() {
        return ''
      }
    },
    isClean: {
      type: Boolean,
      default: true
    },
    disabled: Boolean
  },
  emits: ['didChangePostalCode', 'didChangeCity'],
  data() {
    return {
      draftPostalCode: '',
      draftCity: '',
      isLoading: false,
      zipField,
      cityField
    }
  },
  computed: {
    cityLabel() {
      let label = this.cityField?.label
        ? this.$i18n.t(this.cityField.label)
        : 'City'
      if (!this.draftCity && this.isLoading) {
        label = 'Getting City from Zip code...'
      }
      return label
    }
  },
  watch: {
    postalCode(val) {
      this.draftPostalCode = val
    },
    city(val) {
      this.draftCity = val
    },
    draftPostalCode: async function (val) {
      this.$emit('didChangePostalCode', val)
      if (this.isPostalCodeValid(val)) {
        this.isLoading = true
        try {
          const response = await postalcodeService.search(this.countryCode, val)
          if (response?.data && response?.data[0]?.city) {
            this.draftCity = response?.data[0]?.city
          }
        } catch (error) {
          console.log(error)
        }
        this.isLoading = false
      }
    },
    draftCity(val) {
      this.$emit('didChangeCity', val)
    }
  },
  methods: {
    isPostalCodeValid(postalCode) {
      return postalCode && postalCode.length >= 5 // Look for better checking
    }
  }
}
</script>

<style lang="scss" scoped>
.zip {
  display: flex;
  margin-bottom: 1em;

  pn-input {
    width: 50%;
    margin-bottom: unset;
  }

  pn-input:first-child {
    margin-right: 1em;
  }
}
</style>
