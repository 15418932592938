<template>
  <div class="content">
    <div class="heading">
      <h3>{{ $t('DAILY_BALANCE.CALCULATION_SUPPORT') }}</h3>
      <div v-show="!disabled" class="toggles">
        <pn-button
          :appearance="mode === 'amount' ? 'dark' : 'light'"
          small
          @click="mode = 'amount'"
          >{{ $t('DAILY_BALANCE.AMOUNT') }}</pn-button
        >
        <pn-button
          :appearance="mode === 'quantity' ? 'dark' : 'light'"
          small
          @click="mode = 'quantity'"
          >{{ $t('DAILY_BALANCE.QUANTITY') }}</pn-button
        >
      </div>
    </div>
    <div class="main-content">
      <form @submit.prevent="calculate">
        <div class="fields-grid bordered-bottom">
          <RightLabeledInput
            v-for="(bigBill, index) in bigBillFields"
            :key="index"
            type="number"
            :label="`${bigBill.faction},00`"
            :value="bigBill.value"
            :right-label="mode === 'amount' ? 'SEK' : 'st'"
            :disabled="disabled"
            @input="bigBill.value = $event.target.value"
          />
        </div>
        <hr />
        <div class="fields-grid">
          <RightLabeledInput
            v-for="(smallBill, index) in smallBillFields"
            :key="index"
            type="number"
            :label="`${smallBill.faction},00`"
            :value="smallBill.value"
            :right-label="mode === 'amount' ? 'SEK' : 'st'"
            :disabled="disabled"
            @input="smallBill.value = $event.target.value"
          />
          <div></div>
          <pn-button
            v-show="!disabled"
            appearance="light"
            :icon="money"
            class="grid-button"
            type="submit"
            left-icon
            @click="calculate"
            >{{ $t('DAILY_BALANCE.CALCULATE') }}</pn-button
          >
        </div>
      </form>
      <div class="actions">
        <pn-button
          v-show="!disabled"
          appearance="light"
          :icon="close_circle"
          left-icon
          @click="clearFields"
          >{{ $t('DAILY_BALANCE.CLEAR_FIELDS') }}</pn-button
        >
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { close_circle, money } from '@/shared/constants/icons.js'

export default {
  name: 'DailyBalanceCalculator',
  props: {
    disabled: Boolean
  },
  emits: ['didCalculate', 'didCalculateWithError'],
  data() {
    return {
      bigBillFields: [
        { faction: '1000', value: null },
        { faction: '500', value: null },
        { faction: '200', value: null },
        { faction: '100', value: null },
        { faction: '50', value: null },
        { faction: '20', value: null }
      ],
      smallBillFields: [
        { faction: '10', value: null },
        { faction: '5', value: null },
        { faction: '2', value: null },
        { faction: '1', value: null }
      ],
      mode: 'amount', //amount or quantity
      error: '',
      money,
      close_circle
    }
  },
  watch: {
    error(val) {
      this.$emit('didCalculateWithError', val)
    }
  },
  methods: {
    clearFields() {
      this.bigBillFields.forEach((field) => (field.value = null))
      this.smallBillFields.forEach((field) => (field.value = null))
    },
    getTotal() {
      this.error = ''
      let total = 0

      let reducer = (accumulator, current) => {
        const num = parseInt(current.faction) * parseInt(current.value || 0)
        return accumulator + num
      }

      if (this.mode === 'amount') {
        reducer = (accumulator, current) => {
          const x = parseInt(current.faction)
          const y = parseInt(current.value || 0)
          const isDivisible = y % x === 0
          if (!isDivisible) {
            this.error = `Invalid amount since ${y} is not divisible by ${x}`
            return 0
          }
          return accumulator + y
        }
      }

      if (this.error === '') {
        total = this.bigBillFields.reduce(reducer, total)
        total = this.smallBillFields.reduce(reducer, total)
      }
      return total
    },
    calculate() {
      this.$emit('didCalculate', this.getTotal())
    }
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  margin: 1em 0;
}
.fields-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1em;
  margin: 1em 0;
}
.bordered-botton {
  border-bottom: solid 1px $gray25;
}
.heading {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2em;
}
.toggles {
  display: flex;
  grid-gap: 4px;
}

.grid-button {
  margin: auto;
  width: 100%;
}
</style>
