<template>
  <PageContent
    :heading="heading"
    :is-help-enabled="true"
    :is-loading="loading"
    :is-empty="!filteredForecasts.length"
    @did-click-help="showHelp = true"
  >
    <div class="forecast-content">
      <HelpModal :show="showHelp" @cancel="showHelp = false" />
      <ForecastDetailModal
        :show="modal.show"
        :item="modal.content"
        @did-close="modal.show = false"
      />
      <RangeControls
        @did-change-range="handleDidChangeRange"
        @did-change-date="handleDateChange"
      />
      <ForecastToasts v-show="showToasts" :date-display="toastDateDisplay" />
      <MonthFilters
        v-if="selectedForecast.includes('Months')"
        :is-loading="loading"
        :count="monthCount"
        @did-change-month="handleDidChangeMonth"
      />
      <div v-if="!loading">
        <BarChart
          v-show="
            selectedForecast === '7 Days' && chartMetadata.dataSets.length
          "
          :labels="chartMetadata.labels"
          :data-sets="chartMetadata.dataSets"
          :scales="chartMetadata.scales"
        />
        <DayForecast
          v-if="isSingleDate && dayForecast"
          :content="dayForecast"
        />
        <ForecastTable
          v-else
          :items="filteredForecasts"
          @did-click-details="handleDidClickDetails"
        />
      </div>
    </div>
  </PageContent>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import PageContent from '@/shared/components/PageContent.vue'

import BarChart from './components/BarChart.vue'
import DayForecast from './components/DayForecast.vue'
import ForecastDetailModal from './components/ForecastDetailModal.vue'
import ForecastTable from './components/ForecastTable.vue'
import ForecastToasts from './components/ForecastToasts.vue'
import HelpModal from './components/HelpModal.vue'
import MonthFilters from './components/MonthFilters.vue'
import RangeControls from './components/RangeControls.vue'
import forecastHelperMixin from './forecastHelperMixin'
import { FORECAST_OPTIONS } from './forecastOptions'

export default {
  name: 'Forecast',
  components: {
    PageContent,
    RangeControls,
    MonthFilters,
    BarChart,
    ForecastTable,
    ForecastDetailModal,
    DayForecast,
    ForecastToasts,
    HelpModal
  },
  mixins: [forecastHelperMixin],
  data() {
    return {
      chartMetadata: {
        labels: [],
        dataSets: []
      },
      selectedForecast: FORECAST_OPTIONS.WEEK,
      monthCount: 1,
      filteredForecasts: [],
      dateRange: {
        fromDate: '',
        toDate: ''
      },
      modal: {
        show: false,
        content: null
      },
      showHelp: false,
      heading: {
        title: 'FORECAST.TITLE',
        description: 'FORECAST.DESCRIPTION',
        useTranslation: true
      }
    }
  },
  computed: {
    ...mapState('forecast', ['forecasts', 'loading']),
    ...mapState('retail', ['ssid']),
    isSingleDate() {
      return (
        this.dateRange.fromDate === this.dateRange.toDate &&
        this.dateRange.fromDate !== ''
      )
    },
    dayForecast() {
      const forecast = this.forecasts[0]
      return {
        title: 'Expected parcels',
        count: forecast.parcels,
        date: forecast.date
      }
    },
    toastDateDisplay() {
      if (this.dateRange.fromDate && this.dateRange.toDate) {
        return `${this.dateRange.fromDate} - ${this.dateRange.toDate}`
      } else if (this.dateRange.fromDate) {
        return this.dateRange.fromDate
      }
      return null
    },
    showToasts() {
      return !this.modal.show && !this.showLogoutModal
    }
  },
  watch: {
    forecasts(val) {
      if (this.selectedForecast === FORECAST_OPTIONS.WEEK) {
        this.constructChart()
      }
      const comparator = (a, b) => (a.date < b.date ? -1 : 0)
      const sorted = val.slice().sort(comparator)
      this.filteredForecasts = sorted
    },
    selectedForecast(val) {
      if (val.includes('Months')) {
        this.monthCount = val === FORECAST_OPTIONS.QUARTER ? 3 : 12
      }
    }
  },
  methods: {
    ...mapActions('forecast', ['getBackofficeForecast']),
    handleDidChangeRange(event) {
      this.selectedForecast = event
      if (this.selectedForecast !== '') {
        const dateRange = this.computeDateRange(event)
        const query = {
          fromDate: dateRange.start,
          toDate: dateRange.end
        }
        this.filteredForecasts = []
        this.getBackofficeForecast(query)
      }
    },
    constructChart() {
      // Only construct chart if 7 Days
      this.chartMetadata.labels = this.getLabels(this.selectedForecast)
      this.chartMetadata.dataSets = this.getDataSets(this.selectedForecast)
      this.chartMetadata.scales = this.getScales(this.selectedForecast)
    },
    handleDidChangeMonth(month) {
      if (!month) {
        this.filteredForecasts = this.forecasts
        return
      }
      const map = this.mappedByMonth(this.forecasts)
      this.filteredForecasts = map[month]
    },
    handleDateChange(event) {
      this.dateRange.fromDate = event.startDate
      this.dateRange.toDate = event.endDate
      const query = {
        fromDate: this.dateRange.fromDate,
        toDate: this.dateRange.toDate
      }
      this.filteredForecasts = []
      this.getBackofficeForecast(query)
    },
    handleDidClickDetails(item) {
      this.modal.content = item
      this.modal.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
.user-metadata {
  margin-top: 1em;
  display: flex;
  grid-gap: 4px;
  align-items: center;
  justify-content: space-between;

  a {
    text-decoration: none;
  }
}
.results-content {
  min-height: 500px;
  width: 100%;
  padding: 0 4em;
}
.forecast-content {
  padding: 0 4em;
}
pn-toast {
  margin-bottom: 1em;
}
.forecast-table pn-table .column-wrapper {
  justify-content: unset;
  grid-gap: 8px;
}
@media (max-width: 540px) {
  .results-content {
    padding: unset;
  }
  .forecast-content {
    padding: unset;
  }
}
</style>
