<template>
  <div class="content comment-form">
    <span class="label"> {{ $t('APP.COMMENT') }}: </span>
    <textarea v-model="draftText"></textarea>
    <div v-show="!isLoading" class="actions-right">
      <pn-button
        appearance="light"
        :icon="envelope"
        left-icon
        class="send-email"
        @click="sendEmail"
        >{{ $t('DAILY_BALANCE.SEND_PRINTOUT_TO_EMAIL') }}</pn-button
      >
      <pn-button :icon="print" left-icon class="send-print" @click="comment">{{
        $t('DAILY_BALANCE.SEND_COMMENT_AND_PRINT')
      }}</pn-button>
    </div>
  </div>
</template>

<script>
import { envelope, print } from '@/shared/constants/icons.js'

export default {
  name: 'CommentForm',
  props: {
    text: {
      type: String,
      default() {
        return ''
      }
    },
    isLoading: Boolean
  },
  emits: ['sendComment', 'sendEmail', 'commentWithError'],
  data() {
    return {
      draftText: '',
      envelope,
      print
    }
  },
  watch: {
    text(val) {
      this.draftText = val
    }
  },
  methods: {
    checkIfCommented() {
      let isValid = true
      if (this.draftText === '') {
        isValid = false
        this.$emit('commentWithError')
      }
      return isValid
    },
    comment() {
      if (this.checkIfCommented()) {
        this.$emit('sendComment', this.draftText)
      }
    },
    sendEmail() {
      if (this.checkIfCommented()) {
        this.$emit('sendEmail', this.draftText)
      }
    }
  }
}
</script>

<style scoped lang="scss">
textarea {
  width: 100%;
  min-height: 300px;
  margin: 1em 0;
  outline: none;
  border-radius: 0.8rem;
  padding: 0.5em;
  border: 0.1rem solid $gray700;
  transition: box-shadow 0.2s, border 0.1s;
}

textarea:hover {
  border: 0.1rem solid $blue400;
}

textarea:focus {
  box-shadow: 0 0 0 0.2rem #fff, 0 0 0 0.4rem $blue700;
}
.content {
  display: grid;
  width: 100%;
  margin: 1em 0;
}

.label {
  color: $gray400;
}
</style>
