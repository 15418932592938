<template>
  <div class="status" :class="statusTable[status].style">
    {{ statusTable[status].text }}
    <pn-icon
      small
      :symbol="statusTable[status].icon"
      :color="statusTable[status].iconColor"
    />
  </div>
</template>

<script>
import {
  box_left_off,
  document_return,
  print
} from '@/shared/constants/icons.js'
export default {
  name: 'DeliveryListStatus',
  props: {
    status: {
      type: String,
      default: 'none'
    }
  },
  data() {
    return {
      statusTable: {
        none: {
          icon: '',
          iconColor: '',
          text: '',
          style: ''
        },
        readyForPrint: {
          icon: print,
          iconColor: 'coral700',
          text: 'Ready for print',
          style: 'readyForPrint'
        },
        delivered: {
          icon: box_left_off,
          iconColor: 'green700',
          text: 'Delivered',
          style: 'delivered'
        },
        readyForDelivery: {
          icon: document_return,
          iconColor: 'blue700',
          text: 'Ready for delivery',
          style: ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.status {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  padding: 1px 4px;
  background: $blue50;
  border-radius: 4px;
  color: $blue700;
  margin: 0 1em;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  height: 18px;
  width: fit-content;
}

.status.readyForPrint {
  color: $coral700;
  background: $coral50;
}

.status.delivered {
  color: $green700;
  background: $green50;
}
</style>
