<template>
  <div v-show="show" class="customer-form">
    <div class="form-fields">
      <div class="heading">
        <h4>{{ $t('CUSTOMER_MANAGEMENT.CUSTOMER_INFO') }}</h4>
        <span class="highlighted-label">{{ customerDraft.companyName }}</span>
      </div>
      <div class="customer-form">
        <pn-input
          v-for="(field, index1) in fields1"
          :key="index1"
          :name="field.name"
          :label="$t(field.label)"
          :placeholder="$t(field.label)"
          :required="field.required"
          :disabled="isLoading"
          class="field"
          :error="getError(field)"
          :value="customerDraft[`${field.name}`]"
          :helpertext="field.helperText ? $t(field.helperText) : ''"
          @input="customerDraft[`${field.name}`] = $event.target.value"
        />
        <PostalCodeCityInput
          :postal-code="customerDraft.postalCode"
          :city="customerDraft.city"
          :is-clean="isFormClean"
          :disabled="isLoading"
          @did-change-postal-code="customerDraft.postalCode = $event"
          @did-change-city="customerDraft.city = $event"
        />
        <pn-input
          :name="fields3[0].name"
          :label="$t(fields3[0].label)"
          :placeholder="$t(fields3[0].label)"
          :required="fields3[0].required"
          class="field"
          :disabled="isLoading"
          :error="getError(fields3[0])"
          :value="customerDraft[`${fields3[0].name}`]"
          :helpertext="fields3[0].helperText ? $t(fields3[0].helperText) : ''"
          @input="customerDraft[`${fields3[0].name}`] = $event.target.value"
        />
        <PhoneNumberInput
          :key="phoneComponentKey"
          :name="fields3[1].name"
          :label="$t(fields3[1].label)"
          :placeholder="$t(fields3[1].label)"
          :required="fields3[1].required"
          class="field"
          :disabled="isLoading"
          :error="getError(fields3[1])"
          :value="customerDraft[`${fields3[1].name}`]"
          :helpertext="fields3[1].helperText ? $t(fields3[1].helperText) : ''"
          @did-update-value="customerDraft[`${fields3[1].name}`] = $event"
          @did-validate="handlePhoneValidation"
        />
        <PhoneNumberInput
          :name="fields3[2].name"
          :label="$t(fields3[2].label)"
          :placeholder="$t(fields3[2].label)"
          :required="fields3[2].required"
          class="field"
          :disabled="isLoading"
          :error="getError(fields3[2])"
          :value="customerDraft[`${fields3[2].name}`]"
          :helpertext="fields3[2].helperText ? $t(fields3[2].helperText) : ''"
          @did-update-value="customerDraft[`${fields3[2].name}`] = $event"
          @did-validate="handleMobileValidation"
        />
      </div>
    </div>
    <div class="actions">
      <pn-button :loading="isLoading" @click="save">{{
        $t('APP.SAVE')
      }}</pn-button>
      <pn-button v-show="!isLoading" appearance="light" @click="cancel">{{
        $t('APP.CANCEL')
      }}</pn-button>
    </div>
  </div>
</template>

<script>
import PhoneNumberInput from '@/shared/components/PhoneNumberInput.vue'
import usePhoneNumberFields from '../composables/usePhoneNumberUtils.js'
import * as formFields from '../constants/formFields.js'
import PostalCodeCityInput from './PostalCodeCityInput.vue'

export default {
  name: 'CustomerForm',
  components: {
    PostalCodeCityInput,
    PhoneNumberInput
  },
  props: {
    isLoading: Boolean,
    show: Boolean,
    customer: {
      type: Object,
      default() {
        return null
      }
    }
  },
  emits: ['save', 'cancel', 'didSaveWithError'],
  setup() {
    const {
      isPhoneNumberValid,
      isMobileNumberValid,
      rawPhoneNumber,
      rawMobileNumber,
      phoneComponentKey,
      getSimpleNumber,
      handlePhoneValidation,
      handleMobileValidation
    } = usePhoneNumberFields()
    return {
      isPhoneNumberValid,
      isMobileNumberValid,
      rawPhoneNumber,
      rawMobileNumber,
      phoneComponentKey,
      getSimpleNumber,
      handlePhoneValidation,
      handleMobileValidation
    }
  },
  data() {
    return {
      fields1: [
        formFields.customerName,
        formFields.section,
        formFields.customerNumber,
        formFields.boxAddresses,
        formFields.addressLine2
      ],
      fields2: [formFields.zip, formFields.city],
      fields3: [
        formFields.emailAddress,
        formFields.phoneNumber,
        formFields.mobilePhone
      ],
      customerDraft: { ...formFields.customerFormDefaultState },
      isGettingPostalCodeData: false
    }
  },
  computed: {
    hasNumber() {
      return Boolean(
        this.customerDraft.phoneNumber || this.customerDraft.mobilePhone
      )
    },
    isFormValid() {
      const allFormFields = [...this.fields1, ...this.fields2, ...this.fields3]
      const isAllValid = allFormFields.every((field) => this.getValidity(field))
      return isAllValid && this.hasNumber
    },
    isFormClean() {
      const keys1 = Object.keys(formFields.customerFormDefaultState)
      const keys2 = Object.keys(this.customerDraft)

      if (keys1.length !== keys2.length) {
        return false
      }

      for (const key of keys1) {
        if (
          formFields.customerFormDefaultState[key] !== this.customerDraft[key]
        ) {
          return false
        }
      }
      return true
    }
  },
  watch: {
    show(val) {
      if (!val) {
        this.resetFormState()
      }
    },
    customer(val) {
      if (val) {
        this.customerDraft = { ...val }
        this.customerDraft.boxAddresses = val.address.boxAddresses?.join()
        this.customerDraft.streetAddress = val.address.streetAddress
        delete this.customerDraft.address
      } else {
        this.resetFormState()
      }
    }
  },
  mounted() {
    this.resetFormState()
  },
  methods: {
    preparePayload(customer) {
      const payload = { ...customer }
      delete payload.boxAddresses
      delete payload.streetAddress
      payload.address = {
        boxAddresses: customer.boxAddresses
          ?.split(',')
          .filter((item) => Boolean(item)),
        streetAddress: customer.streetAddress
      }
      if (this.rawPhoneNumber?.nationalNumber?.length <= 8) {
        payload.phoneNumber = this.getSimpleNumber(this.rawPhoneNumber)
      }
      return payload
    },
    save() {
      if (this.isFormValid) {
        const payload = this.preparePayload(this.customerDraft)
        this.$emit('save', payload)
      } else {
        let error = this.$i18n.t('CUSTOMER_MANAGEMENT.GENERIC_FORM_ERROR')
        if (!this.hasNumber) {
          error = this.$i18n.t('CUSTOMER_MANAGEMENT.NO_NUMBER_FORM_ERROR')
        }
        this.$emit('didSaveWithError', error)
      }
    },
    cancel() {
      this.resetFormState()
      this.$emit('cancel')
    },
    getValidity(field) {
      let isValid = field.validation(this.customerDraft[`${field.name}`])
      if (field.name === 'phoneNumber') {
        isValid = this.getNumberFieldFinalValidity(
          field,
          this.isPhoneNumberValid
        )
      } else if (field.name === 'mobilePhone') {
        isValid = this.getNumberFieldFinalValidity(
          field,
          this.isMobileNumberValid
        )
      }
      return isValid
    },
    getError(field) {
      return this.isFormClean || this.getValidity(field)
        ? ''
        : this.$i18n.t(field.error)
    },
    getNumberFieldFinalValidity(field, isValid) {
      const value = this.customerDraft[`${field.name}`]
      const isEmpty = value === ''
      const isRegexValid = field.validation(value)
      return isEmpty || (!isEmpty && (isValid || isRegexValid))
    },
    resetFormState() {
      this.phoneComponentKey += 1
      this.customerDraft = { ...formFields.customerFormDefaultState }
    }
  }
}
</script>

<style lang="scss" scoped>
.customer-form {
  margin-top: 4em;
}
.field {
  width: 100%;
}

.actions {
  width: 100%;

  pn-button {
    width: 100%;
    margin-bottom: 1em;
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px $gray50;
}
</style>
