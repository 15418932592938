import { reactive, ref } from 'vue'

export default function usePhoneNumberFields() {
  const isPhoneNumberValid = ref(false)
  const isMobileNumberValid = ref(false)
  const rawPhoneNumber = reactive({
    countryCallingCode: null,
    nationalNumber: null,
    formatted: null
  })
  const rawMobileNumber = reactive({
    countryCallingCode: null,
    nationalNumber: null,
    formatted: null
  })
  const phoneComponentKey = ref(0)

  const getSimpleNumber = (rawNumber) => {
    if (rawNumber?.countryCallingCode && rawNumber?.nationalNumber) {
      return `+${rawNumber.countryCallingCode} ${rawNumber.nationalNumber}`
    }
    return null
  }

  const handlePhoneValidation = (event) => {
    isPhoneNumberValid.value = event.valid === true
    rawPhoneNumber.countryCallingCode = event?.countryCallingCode
    rawPhoneNumber.nationalNumber = event?.nationalNumber
    rawPhoneNumber.formatted = event?.formatted
  }

  const handleMobileValidation = (event) => {
    isMobileNumberValid.value = event.valid === true
    rawMobileNumber.countryCallingCode = event?.countryCallingCode
    rawMobileNumber.nationalNumber = event?.nationalNumber
    rawMobileNumber.formatted = event?.formatted
  }

  return {
    isPhoneNumberValid,
    rawPhoneNumber,
    rawMobileNumber,
    phoneComponentKey,
    getSimpleNumber,
    handlePhoneValidation,
    handleMobileValidation
  }
}
