import { alert_exclamation_triangle } from '@/shared/constants/icons.js'
import { mapActions, mapState } from 'vuex'

/**
 * This is for using the Retail token to get a temporary IAM
 * token that can be use to access E-shop and Basic Register
 */
export default {
  data() {
    return {
      isLoading: false,
      autoLoggedInUrl: null,
      storeSubscription: null,
      error: ''
    }
  },
  computed: {
    ...mapState('retail', ['iamToken'])
  },
  mounted() {
    this.exchangeToken()
    this.storeSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === 'retail/didGetIamToken') {
        this.requestAutoLogin()
      }
    })
  },
  unmounted() {
    if (this.storeSubscription) {
      this.storeSubscription()
    }
  },
  methods: {
    ...mapActions('retail', ['exchangeToken', 'clearIamToken']),
    ...mapActions('toaster', ['showToaster']),
    showError(error) {
      this.showToaster({
        showClose: true,
        icon: alert_exclamation_triangle,
        appearance: 'warning',
        message: error,
        show: true
      })
    },
    navigateBack() {
      if (!this.isMobile()) {
        this.$router.push({ path: '/dashboard' })
      }
    }
  }
}
