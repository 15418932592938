<template>
  <div class="filter-controls">
    <pn-search-field
      class="main-search"
      :placeholder="$t('APP.SEARCH')"
      :value="filters.id"
      button="icon"
      light
      @update="handleUpdateId"
      @search="handleSearch"
    />
    <div :key="componentKey" class="sub-search-filters">
      <CustomerSelector
        :value="filters.agreementId"
        class="customer-selector"
        @did-select="handleDidSelectCustomer"
      />
      <pn-date-picker
        range
        label="Date range:"
        placeholder="YYYY-MM-DD"
        end-placeholder="YYYY-MM-DD"
        :min-date="dateRangeLimit.min"
        :start="filters.dateFrom"
        :end="filters.dateTo"
        @dateselection="handleDateSelection"
      />
      <pn-button
        v-show="!isFiltersEmpty"
        small
        variant="borderless"
        appearance="light"
        :icon="trash_can"
        @click="clearFilters"
        >{{ `${$t('APP.CLEAR')} ${$t('APP.FILTERS')}` }}</pn-button
      >
    </div>
    <div class="flex-group status-filters">
      <span class="label1">{{ $t('DELIVERY_LIST.FILTER_BY') }}</span>
      <pn-choice-chip
        v-for="(choice, index) in statusFilters"
        :key="index"
        :choiceid="`choice-${choice.value}`"
        :checked="draftSelectedStates.includes(choice.value)"
        :value="choice.value"
        @change="updateSelectedStates(choice.value)"
        >{{ $t(choice.label) }}
      </pn-choice-chip>
    </div>
  </div>
</template>

<script>
import { trash_can } from '@/shared/constants/icons.js'
import { dateToYMD } from '@postnord/retail-backoffice-components/dist'
import useSearchFilters from '../composables/useSearchFilters.js'
import CustomerSelector from './CustomerSelector.vue'

export default {
  name: 'DeliveryListsFilterControl',
  components: {
    CustomerSelector
  },
  props: {
    selectedStates: {
      type: Array,
      default() {
        return []
      }
    }
  },
  emits: [
    'didUpdateId',
    'didSearchId',
    'didSelectAgreement',
    'didChangeDateRange',
    'didClearFilters',
    'didUpdateSelectedStates'
  ],
  setup() {
    const { filters, clearFilterState } = useSearchFilters()
    return {
      filters,
      clearFilterState
    }
  },
  data() {
    return {
      componentKey: 0,
      draftSelectedStates: [],
      statusFilters: [
        { label: 'DELIVERY_LIST.READY_FOR_PRINT', value: 'readyForPrint' },
        {
          label: 'DELIVERY_LIST.READY_FOR_DELIVERY',
          value: 'readyForDelivery'
        },
        { label: 'DELIVERY_LIST.DELIVERED', value: 'delivered' }
      ],
      trash_can
    }
  },
  computed: {
    isFiltersEmpty() {
      return Object.values(this.filters).every(
        (prop) => prop === null || prop === '' || prop.length === 0
      )
    },
    dateRangeLimit() {
      const date1 = new Date()
      const offset = 3
      return {
        min: dateToYMD(new Date(date1.setMonth(date1.getMonth() - offset)))
      }
    }
  },
  watch: {
    selectedStates(val) {
      this.draftSelectedStates = [...val]
    }
  },
  methods: {
    handleUpdateId(event) {
      this.filters.id = event.detail
      this.$emit('didUpdateId', event.detail)
    },
    handleSearch(event) {
      this.filters.agreementId = ''
      this.filters.dateFrom = ''
      this.filters.dateTo = ''
      this.$emit('didSearchId', event.detail)
    },
    handleDidSelectCustomer(event) {
      this.filters.agreementId = event
      this.$emit('didSelectAgreement', event)
    },
    async clearFilters() {
      this.clearFilterState()
      this.draftSelectedStates = []
      this.$emit('didUpdateSelectedStates', [])
      this.$emit('didClearFilters')
      this.forceRender()
    },
    handleDateSelection(event) {
      this.filters.dateFrom = event.detail.start
      this.filters.dateTo = event.detail.end
      this.$emit('didChangeDateRange', {
        dateFrom: event.detail.start,
        dateTo: event.detail.end
      })
    },
    forceRender() {
      this.componentKey += 1
    },
    updateSelectedStates(state) {
      if (this.draftSelectedStates.includes(state)) {
        this.draftSelectedStates = this.draftSelectedStates.filter(
          (item) => item !== state
        )
      } else {
        this.draftSelectedStates = [...this.draftSelectedStates, state]
      }
      this.$emit('didUpdateSelectedStates', this.draftSelectedStates)
    }
  }
}
</script>

<style lang="scss" scoped>
.main-search {
  width: 100%;
}

.sub-search-filters {
  display: flex;
  align-items: baseline;
  grid-gap: 1em;

  pn-date-picker {
    top: 2px;
  }
}

::v-deep(pn-date-picker input) {
  text-align-last: center;
}

.filter-controls {
  margin: 2em 0;
  border-bottom: solid 1px $gray50;

  .customer-selector {
    margin: 1em 0;
    width: 30%;
  }
}
</style>
