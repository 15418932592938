<template>
  <div class="loader">
    <BrandLogo color="#969087" class="gray-logo" />
    <div class="loading-title">Postnord Retail Backoffice</div>
    <!-- {{ title }} -->
    <h4>Loading...</h4>
    <!-- {{ $t('APP.LOADING') }} -->
    <pn-icon :symbol="spinner_blue" color="black" />
  </div>
</template>

<script setup>
import { spinner_blue } from '@/shared/constants/icons.js'
</script>

<style lang="scss" scoped>
.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: $gray50;
  .loading-title {
    margin-bottom: 10px;
    color: $gray700;
  }
  .gray-logo {
    max-width: 300px;
    margin: 5px 10px;
  }
  pn-icon {
    margin-top: 1em;
  }
}
</style>
