<template>
  <div class="content">
    <EmptyContent
      :illustration="hand_lock_money_package"
      title="Unauthorized"
      description="You do not have the correct permissions to access this page. Please contact through #retail-backoffice-tools"
    />
  </div>
</template>
<script>
import { hand_lock_money_package } from '@/shared/constants/illustrations.js'

export default {
  name: 'Unauthorized',
  data() {
    return {
      hand_lock_money_package
    }
  }
}
</script>
<style scoped lang="scss">
.content {
  text-align: center;
  padding: 2em;
  min-height: 500px;
}
</style>
