<template>
  <div v-if="item">
    <pn-modal :open="show" @close="closeModal">
      <DayForecast v-if="content" :content="content" />
    </pn-modal>
  </div>
</template>
<script>
import DayForecast from './DayForecast.vue'

export default {
  name: 'ForecastDetailModal',
  components: {
    DayForecast
  },
  props: {
    show: Boolean,
    item: {
      type: Object,
      default() {
        return null
      }
    }
  },
  emits: ['didClose'],
  computed: {
    content() {
      if (this.item) {
        return {
          title: 'FORECAST.EXPECTED_PARCELS',
          count: this.item.parcels,
          date: this.item.date
        }
      }
      return null
    }
  },
  methods: {
    closeModal() {
      this.$emit('didClose')
    }
  }
}
</script>
