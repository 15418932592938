<template>
  <PageContent>
    <div class="content">
      <div class="container">
        <pn-toast closable="false" :icon="alert_info_circle">
          {{ $t('BASEREGISTER.CONNECTION_REQUIREMENT') }}
        </pn-toast>
      </div>
      <EmptyContent
        :illustration="person_computer"
        :title="$t('BASEREGISTER.TITLE')"
        :description="description"
      />
      <Loader v-if="isLoading" />
      <pn-button
        v-if="autoLoggedInUrl"
        :icon="open_in_new"
        :href="autoLoggedInUrl"
        :target="isMobile() ? '' : '_blank'"
        @click="navigateBack"
      >
        {{ $t('BASEREGISTER.PROCEED') }}
      </pn-button>
    </div>
  </PageContent>
</template>
<script>
import PageContent from '@/shared/components/PageContent.vue'
import useMobileDetection from '@/shared/composables/useMobileDetection.js'
import useValidators from '@/shared/composables/useValidators.js'
import { alert_info_circle, open_in_new } from '@/shared/constants/icons.js'
import { person_computer } from '@/shared/constants/illustrations.js'
import autoLoginRedirectPageMixin from '@/shared/mixins/autoLoginRedirectPageMixin.js'
import baseRegisterService from './services/baseRegisterService.js'

export default {
  name: 'BaseRegister',
  components: {
    PageContent
  },
  mixins: [autoLoginRedirectPageMixin],
  setup() {
    const { isUrl } = useValidators()
    const { isMobile } = useMobileDetection()
    return { isUrl, isMobile }
  },
  data() {
    return {
      alert_info_circle,
      open_in_new,
      person_computer
    }
  },
  computed: {
    description() {
      let text = this.$i18n.t('APP.LOADING_IAM_TOKEN')
      if (this.autoLoggedInUrl) {
        text = this.$i18n.t('APP.REDIRECT_MESSAGE')
      } else if (this.error) {
        text = this.$i18n.t('APP.UNABLE_TO_REDIRECT')
      }
      return text
    }
  },
  methods: {
    async requestAutoLogin() {
      this.isLoading = true
      try {
        const response = await baseRegisterService.login(this.iamToken)
        if (this.isUrl(response?.data?.path)) {
          this.autoLoggedInUrl = response?.data?.path
        }
      } catch (error) {
        this.showError(this.$i18n.t('APP.UNABLE_TO_REDIRECT'))
        this.error = error
        this.clearIamToken()
      }
      this.isLoading = false
    }
  }
}
</script>
<style scoped lang="scss">
.content {
  text-align: center;
  min-height: 500px;

  pn-button {
    margin-top: 1em;
  }
}
.container {
  margin: 1em 0;
}
</style>
