<template>
  <div class="header">
    <h1>{{ useTranslation ? $t(title) : title }}</h1>
    <span>{{ useTranslation ? $t(description) : description }}</span>
  </div>
</template>

<script>
export default {
  name: 'MainHeader',
  props: {
    useTranslation: Boolean,
    title: {
      type: String,
      default() {
        return ''
      }
    },
    description: {
      type: String,
      default() {
        return ''
      }
    },
    showBackofficeLink: Boolean
  },
  data() {
    return {
      backofficeUrl: `${process.env.VUE_APP_MAIN_BACKOFFICE_URL}`
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  width: 100%;
  height: 150px;
  color: $black;
  padding: 1em 0.5em;

  h1 {
    margin: 8px 0;
  }
}
</style>
