<template>
  <PageContent
    :heading="heading"
    :is-help-enabled="false"
    :is-loading="loading"
    :is-empty="isEmpty && !loading"
  >
    <div class="main-content">
      <div class="flex-group menu-bar">
        <div>
          <div class="search-input">
            <pn-search-field
              label="Search"
              placeholder="Filter Item ID..."
              button="none"
              :loading="loading"
              :value="searchText"
              @update="searchText = $event.target.value"
            />
          </div>
        </div>
        <div>
          <div class="flex-group button-controls">
            <TotalCounter :count="items.length" />
          </div>
        </div>
      </div>
      <div class="results-content">
        <ArrivedItemsTable
          v-if="!isEmpty"
          :items="items"
          :search-text="searchText"
          @will-print-item="handlePrintItem"
        />
      </div>
    </div>
  </PageContent>
</template>

<script>
import PageContent from '@/shared/components/PageContent.vue'
import TotalCounter from '@/shared/components/TotalCounter.vue'
import usePrint from '@/shared/composables/usePrint.js'
import useToast from '@/shared/composables/useToast.js'
import { v4 as uuidv4 } from 'uuid'
import { mapActions, mapState } from 'vuex'
import ArrivedItemsTable from './components/ArrivedItemsTable.vue'

export default {
  components: {
    PageContent,
    TotalCounter,
    ArrivedItemsTable
  },
  setup() {
    const { successToast, showToaster } = useToast()
    const { printPdf, generateReportErrorToast } = usePrint()
    return {
      successToast,
      showToaster,
      printPdf,
      generateReportErrorToast
    }
  },
  data() {
    return {
      searchText: '',
      heading: {
        title: this.$i18n.t('FALLBACK.TITLE'),
        description: this.$i18n.t('FALLBACK.DESCRIPTION')
      },
      subscriptions: null
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapState('retail', ['ssid']),
    ...mapState('fallback', ['items']),
    isEmpty() {
      return !this.items.length && !this.loading
    }
  },
  mounted() {
    this.subscribeMutations()
    this.getArrivedItems()
  },
  unmounted() {
    this.subscriptions()
  },
  methods: {
    ...mapActions('retail', ['getPdf']),
    ...mapActions('fallback', ['getArrivedItems', 'putEvents']),
    handlePrintItem(item) {
      const payload = {
        eventList: {
          customersessions: [
            {
              id: uuidv4(),
              localNotifications: [
                {
                  itemId: item.itemId,
                  productCode: 'RP',
                  timeStamp: new Date().toISOString(),
                  emailAddresses: ['']
                }
              ]
            }
          ]
        }
      }
      this.putEvents(payload)
    },
    handlePrintData(data) {
      this.printPdf(data)
      this.showDidPrintState()
    },
    showDidPrintState() {
      this.successToast.message = 'Local notification printed.'
      this.showToaster(this.successToast)
    },
    subscribeMutations() {
      this.subscriptions = this.$store.subscribe((mutation) => {
        if (mutation.type === 'retail/didGetPdf') {
          const pdfData = mutation?.payload?.data
          if (pdfData) {
            this.handlePrintData(pdfData)
          } else {
            this.showToaster(this.generateReportErrorToast)
          }
        } else if (mutation.type === 'fallback/didPutEvents') {
          const printId = mutation.payload
          if (printId) {
            this.getPdf(printId)
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  margin: 2em 0;
}
.button-controls {
  margin-top: 1em;
  justify-content: right;
}
.button-controls pn-button {
  width: 200px;
}
.search-input {
  display: flex;
  justify-content: right;
}
.search-input pn-input {
  width: 100%;
}
.menu-bar {
  justify-content: space-between;
}
</style>
