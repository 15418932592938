<template>
  <div v-if="item">
    <ItemHeaders
      :headers="headers"
      :use-translation="true"
      :extra-columns="1"
    />
    <div class="item-row">
      <div class="row">
        <div class="column list-id extend" @click="selectDeliveryList(item)">
          {{ item.deliveryListId ? item.deliveryListId : '' }}
          -
          {{ item.agreementName ? item.agreementName : '' }}
        </div>
        <div class="column">
          <span>{{ item.created }}</span>
        </div>
        <div class="column actions">
          <pn-button
            appearance="light"
            :icon="arrow_right"
            small
            @click="selectDeliveryList(item)"
          >
            {{ $t('APP.DETAILS') }}
          </pn-button>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <DeliveryListStatus :status="item.state" />
        </div>
        <div class="column actions">
          <span class="label">
            {{ $t('DELIVERY_LIST.CONTAINS_ITEM_ID') }}: {{ searchText }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemHeaders from '@/shared/components/ItemHeaders.vue'
import { arrow_right } from '@/shared/constants/icons.js'
import DeliveryListStatus from './DeliveryListStatus.vue'

export default {
  name: 'ItemSearchResult',
  components: {
    ItemHeaders,
    DeliveryListStatus
  },
  props: {
    item: {
      type: Object,
      default() {
        return null
      }
    },
    searchText: {
      type: String,
      default() {
        return ''
      }
    }
  },
  emits: ['didSelect'],
  data() {
    return {
      headers: [
        {
          label: 'DELIVERY_LIST.ID',
          isSortable: true,
          isExtended: true,
          sortKey: 'deliveryListId'
        },
        {
          label: 'APP.DATE',
          isSortable: true,
          sortKey: 'created'
        }
      ],
      arrow_right
    }
  },
  methods: {
    selectDeliveryList(item) {
      this.$emit('didSelect', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.item-row {
  padding: unset;
  .row {
    padding: 1em;
    align-items: center;

    ::v-deep(.status) {
      margin: unset;
    }
  }
  .row:first-child {
    border-bottom: solid 1px $gray400;
  }
}
.column {
  flex: 1;
}

.column.extend {
  flex: 1.5;
}

.list-id {
  font-weight: 500;
  cursor: pointer;
}

.actions {
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 700px) {
  .column {
    flex: unset;
  }
  .actions {
    margin-top: 1em;
    width: 100%;
    pn-button {
      width: 50%;
    }
  }
}

@media (max-width: $breakpoint-phone) {
  .item-row .column {
    width: 100%;
    margin-bottom: 4px;
  }
}
</style>
