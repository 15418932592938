<template>
  <div class="month-filters">
    <div v-if="!isLoading" class="flex-group">
      {{ $t('FORECAST.SEE_FORECAST_FOR') }}
      <pn-choice-chip
        v-for="(choice, index) in months"
        :key="index"
        :choiceid="index"
        :checked="selectedMonth == choice"
        :value="choice"
        @change="handleMonthChange"
        >{{ choice }}
      </pn-choice-chip>
    </div>
    <div v-else class="flex-group">
      {{ $t('FORECAST.SEE_FORECAST_FOR') }}
      <div v-for="index in 3" :key="index" class="line shimmer"></div>
    </div>
  </div>
</template>

<script>
import { getNextMonths } from '@postnord/retail-backoffice-components/dist'

export default {
  name: 'MonthFilters',
  props: {
    count: {
      type: Number,
      default() {
        return 0
      }
    },
    isLoading: Boolean
  },
  emits: ['didChangeMonth'],
  data() {
    return {
      selectedMonth: null,
      months: []
    }
  },
  watch: {
    count: {
      immediate: true,
      handler(val) {
        this.months = getNextMonths(val)
      }
    }
  },
  methods: {
    handleMonthChange(event) {
      this.selectedMonth =
        event.target.value === this.selectedMonth ? null : event.target.value
      this.$emit('didChangeMonth', this.selectedMonth)
    }
  }
}
</script>
