<template>
  <div class="forecast-table">
    <pn-table v-if="items && items.length">
      <table aria-describedby="Forecast Table">
        <thead>
          <tr>
            <th scope="date">
              {{ $t('APP.DATE') }}
              <pn-icon :symbol="calendar" small="false" color="blue700" />
            </th>
            <th scope="parcels">
              {{ $t('FORECAST.EXPECTED_PARCELS') }}
              <pn-icon :symbol="box_label" small="false" color="blue700" />
            </th>
            <th scope="actions"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <div class="flex-group">
                <h4 v-if="isDateInThisWeek(item.date)">
                  {{ getDayOfDate(item.date) }}
                </h4>
                <span>{{ simpleDate(item.date) }}</span>
              </div>
            </td>
            <td>
              <span class="detail">{{ item.parcels }}</span>
            </td>
            <td class="actions">
              <pn-button
                small
                :icon="arrow_right"
                appearance="light"
                @click="showDetail(item)"
              >
                {{ $t('APP.DETAILS') }}
              </pn-button>
            </td>
          </tr>
        </tbody>
      </table>
    </pn-table>
  </div>
</template>

<script>
import { arrow_right, box_label, calendar } from '@/shared/constants/icons.js'
import {
  WEEKDAY_NAMES,
  dateToYMD,
  isDateInThisWeek
} from '@postnord/retail-backoffice-components/dist'

export default {
  name: 'ForecastTable',
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  emits: ['didClickDetails'],
  data() {
    return {
      arrow_right,
      box_label,
      calendar
    }
  },
  methods: {
    getDayOfDate(dateString) {
      const date = new Date(dateString)
      return WEEKDAY_NAMES[date.getDay()]
    },
    isDateInThisWeek(dateString) {
      const date = new Date(dateString)
      return isDateInThisWeek(date)
    },
    showDetail(item) {
      this.$emit('didClickDetails', item)
    },
    simpleDate(val) {
      const date = new Date(val)
      return dateToYMD(date)
    }
  }
}
</script>

<style scoped lang="scss">
.forecast-table {
  margin: 1em 0;
}
.detail {
  font-weight: 500;
  color: $black;
}
td.actions {
  text-align: right;
  pn-button {
    float: right;
  }
}
h4 {
  margin: unset;
}
table td:first-child {
  width: 50%;
}
table td:nth-child(2) {
  width: 40%;
}
table td:nth-child(3) {
  width: 10%;
}
.flex-group {
  padding-bottom: unset;
}
</style>
