import {
  dateToYMD,
  getNextMonths,
  groupBy,
  MONTH_NAMES
} from '@postnord/retail-backoffice-components/dist'
import { FORECAST_OPTIONS } from './forecastOptions.js'

export const reducer = (previousValue, currentValue) => {
  return previousValue + currentValue
}

export default {
  data() {
    return {
      monthNames: MONTH_NAMES
    }
  },
  computed: {
    currentMonthString() {
      return this.monthNames[new Date().getUTCMonth()]
    }
  },
  methods: {
    computeDateRange(val) {
      const startDate = new Date()
      const endDate = new Date()
      let range = 0
      switch (val) {
        case FORECAST_OPTIONS.WEEK:
          range = 7
          break
        case FORECAST_OPTIONS.MONTH:
          range = 30
          break
        case FORECAST_OPTIONS.QUARTER:
          range = 30 * 3
          break
        case FORECAST_OPTIONS.YEAR:
          range = 30 * 12
          break
        default:
          break
      }
      endDate.setDate(endDate.getDate() + range)
      const start = dateToYMD(startDate)
      const end = dateToYMD(endDate)
      return { start, end }
    },
    nextDates(days) {
      const dates = []
      for (let step = 0; step <= days; step++) {
        const date = new Date()
        date.setDate(date.getDate() + step)
        dates.push(date)
      }
      return dates
    },
    nextMonths(count) {
      return getNextMonths(count)
    },
    mappedLabels(days) {
      return this.nextDates(days).map((dateObj) => {
        const month = dateObj.getUTCMonth() + 1
        const day = dateObj.getUTCDate()
        return `${day}/${month}`
      })
    },
    getLabels(val) {
      let labels = []
      switch (val) {
        case FORECAST_OPTIONS.WEEK:
          labels = this.mappedLabels(7)
          break
        case FORECAST_OPTIONS.MONTH:
          labels = this.mappedLabels(30)
          break
        case FORECAST_OPTIONS.QUARTER:
          labels = this.nextMonths(3)
          break
        case FORECAST_OPTIONS.YEAR:
          labels = this.nextMonths(12)
          break
      }
      return labels
    },
    mappedByMonth(items) {
      return groupBy(items, (item) => {
        const month = new Date(item.date).getMonth() % 12
        return this.monthNames[month]
      })
    },
    getDataSets(val) {
      let dataSets = []
      if (
        val === FORECAST_OPTIONS.SEMI_ANNUAL ||
        val === FORECAST_OPTIONS.YEAR
      ) {
        const mapped = this.mappedByMonth(this.forecasts)
        const currentMonth = mapped[this.currentMonthString]

        const dataSet1 = {
          backgroundColor: '#005D92',
          data: [currentMonth.map((item) => item.parcels).reduce(reducer, 0)]
        }
        dataSets.push(dataSet1)

        const otherMonths = this.getOtherMonths
        const dataSet2 = {
          backgroundColor: '#8EDDF9',
          data: otherMonths
        }
        dataSets.push(dataSet2)
      } else {
        const data = this.forecasts.map((item) => {
          return item.parcels
        })
        dataSets = [
          {
            backgroundColor: '#005D92',
            data: data
          }
        ]
      }
      return dataSets
    },
    getOtherMonths(mapped) {
      return Object.keys(mapped).filter((month) => {
        if (month !== this.currentMonthString) {
          return mapped[month].map((item) => item.parcels).reduce(reducer, 0)
        }
        return false
      })
    },
    getScales(val) {
      let barPercentage = 0.01
      let stepSize = 100
      switch (val) {
        case FORECAST_OPTIONS.WEEK:
          barPercentage = 0.1
          break
        case FORECAST_OPTIONS.MONTH:
          barPercentage = 0.3
          break
        case FORECAST_OPTIONS.QUARTER:
          barPercentage = 0.5
          break
        case FORECAST_OPTIONS.YEAR:
          barPercentage = 0.2
          stepSize = 500
          break
      }
      return {
        xAxes: [{ barPercentage }],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize
            }
          }
        ]
      }
    }
  }
}
