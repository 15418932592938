<template>
  <div>
    <pn-select
      :label="$t('DELIVERY_LIST.SELECT_CUSTOMER')"
      :placeholder="$t('DELIVERY_LIST.SELECT_CUSTOMER')"
      :value="selected"
      :search-placeholder="$t('APP.SEARCH')"
      :searchable="true"
      @selectchange="selectCustomer"
    >
      <pn-option
        v-for="(customer, index) in sortedItems"
        :key="index"
        :value="customer.id"
        :selected="customer.id === selected"
        >{{ customer.companyName }}</pn-option
      >
    </pn-select>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CustomerSelector',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  emits: ['didSelect'],
  data() {
    return {
      selected: null
    }
  },
  computed: {
    ...mapState('deliveryAgreements', ['items']),
    sortedItems() {
      return this.items
        .slice()
        .sort((a, b) => a.companyName.localeCompare(b.companyName))
    }
  },
  watch: {
    value(val) {
      this.selected = val
    }
  },
  mounted() {
    this.getDeliveryAgreements()
  },
  methods: {
    ...mapActions('deliveryAgreements', ['getDeliveryAgreements']),
    selectCustomer(event) {
      this.selected = event.detail
      this.$emit('didSelect', this.selected)
    }
  }
}
</script>
