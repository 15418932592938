<template>
  <div class="flex-group toasts">
    <pn-toast v-if="dateDisplay" closable="false" :icon="alert_info_circle">
      {{ $t('FORECAST.DATE_CHANGED') }}:
      <span class="blue">[ {{ dateDisplay }} ]</span>
    </pn-toast>
    <pn-toast closable="false" :icon="alert_info_circle" class="info-toast">
      {{ $t('FORECAST.PROJECTIONS_MESSAGE') }}
    </pn-toast>
  </div>
</template>

<script>
import { alert_info_circle } from '@/shared/constants/icons.js'

export default {
  name: 'ForecastToasts',
  props: {
    dateDisplay: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      alert_info_circle
    }
  }
}
</script>

<style scoped lang="scss">
.toasts {
  justify-content: space-between;
}
span.blue {
  color: $blue700;
  font-weight: 500;
}
.toasts .info-toast {
  margin-left: auto;
}
</style>
