<template>
  <div>
    <canvas id="chart-canvas"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
export default {
  name: 'BarChart',
  props: {
    labels: { type: Array, default: () => [] },
    dataSets: { type: Array, default: () => [] },
    scales: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      chartObject: null
    }
  },
  watch: {
    labels() {
      this.renderChart()
    },
    dataSets() {
      this.renderChart()
    }
  },
  mounted() {
    if (this.labels.length && this.dataSets.length) {
      this.renderChart()
    }
  },
  methods: {
    renderChart() {
      const chartData = {
        type: 'bar',
        data: {
          labels: this.labels,
          datasets: this.dataSets
        },
        options: {
          plugins: {
            legend: {
              display: false
            }
          },
          scales: this.scales
        }
      }
      const ctx = document.getElementById('chart-canvas').getContext('2d')
      Chart.register(...registerables)
      this.chartObject = new Chart(ctx, chartData)
    }
  }
}
</script>

<style lang="scss" scoped>
#chart-canvas {
  width: 100% !important;
}
</style>
