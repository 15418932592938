import axios from 'axios'
import { decoratedRequestWithApiKey } from './serviceDecorators.js'

const serviceInstance = axios.create({
  baseURL: `${process.env.VUE_APP_POSTALCODE_BASE_URL}`
})

serviceInstance.interceptors.request.use((request) => {
  return decoratedRequestWithApiKey(request)
})

export default {
  search: async (countryCode, postalCode) => {
    return serviceInstance.get(`/${countryCode}/${postalCode}`)
  }
}
