export {
  arms_packages_plant,
  character_computer_graphics,
  character_package_sweather,
  claims,
  company_settings,
  computer_postcard,
  customer_service,
  dashboard,
  discover,
  file_paper_arrow,
  hand_lock_money_package,
  hand_mobile_stamp,
  hand_paper_money,
  mailbox_plant_letters,
  mobile_notification,
  newsletter_map_calendar,
  notifications,
  package_calendar,
  person_computer,
  pex,
  postagemeter_letters_plant,
  postboxes,
  postnord_customer_service,
  reports,
  tracking,
  truck_big_mountains,
  truck_house_arrow,
  truck_packages
} from 'pn-design-assets/pn-assets/illustrations'
