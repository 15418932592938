import { ref } from 'vue'

export default function useCommenting() {
  const commentDraft = ref('')
  const confirmButtonCounter = ref(0)
  const maxConfirmClicks = 3

  return {
    commentDraft,
    confirmButtonCounter,
    maxConfirmClicks
  }
}
