import { ref } from 'vue'

export default function useEmailHandler() {
  const emailModal = ref({
    show: false,
    email: ''
  })

  const shouldEmail = ref(false)
  const showResendEmail = ref(false)

  return {
    emailModal,
    shouldEmail,
    showResendEmail
  }
}
