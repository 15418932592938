<template>
  <div>
    <pn-tooltip :color="color">
      <p>
        Please provide a valid phone number format. This format includes the
        country code, area code, and phone number separated by spaces, as
        follows: +00 000 0000000
      </p>
      <p>The following formats are allowed for the phone number:</p>
      <ul>
        <li>Country code: +00, +000</li>
        <li>Area code: 00, 000</li>
        <li>
          Phone number:
          <ul>
            <li>6-8 digits: 000000, 0000000, 00000000</li>
            <li>6-8 with space in between: 000 000, 000 0000, 0000 0000</li>
            <li>00 00 00, 000 00 00</li>
          </ul>
        </li>
      </ul>
      <p>
        Please make sure that your phone number matches one of these formats.
      </p>
    </pn-tooltip>
  </div>
</template>

<script>
export default {
  name: 'PhoneNumberTooltip',
  props: {
    color: {
      type: String,
      default() {
        return 'blue'
      }
    }
  }
}
</script>
