<template>
  <div class="content">
    {{ label }} <span class="chip">{{ count }}</span>
  </div>
</template>

<script>
export default {
  name: 'TotalCounter',
  props: {
    count: {
      type: [Number, String],
      default: 0
    },
    label: {
      type: String,
      default: 'Total results:'
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  align-items: center;
}
.chip {
  width: fit-content;
  border-radius: 4px;
  text-align: center;
  padding: 1px 6px;
  margin: 0 3px;
  font-size: 0.8em;
  background-color: $blue700;
  color: $white;
}
</style>
