<template>
  <div>
    <Loader v-if="isSecuringRoute" :overlay="true" />

    <div v-show="hasHeadingContent" class="page-heading">
      <MainHeader
        :title="heading.title"
        :description="heading.description"
        :use-translation="heading.useTranslation"
      />

      <div class="page-heading-nav">
        <router-link :to="backPath">
          <pn-icon :symbol="arrow_left" small="false" color="blue700" />
          {{ backText }}
        </router-link>

        <p v-show="isHelpEnabled">
          <pn-button
            appearance="light"
            :icon="alert_question_circle"
            variant="borderless"
            @click="clickHelp"
          >
            {{ $t('APP.HELP') }}
          </pn-button>
        </p>
      </div>
    </div>

    <div class="page-body">
      <div v-if="!isSecuringRoute">
        <slot></slot>
      </div>

      <EmptyContent
        v-show="isEmpty"
        :is-loading="isLoading"
        :title="$t('APP.EMPTY_TITLE')"
        :description="$t('APP.EMPTY_DESCRIPTION')"
      />

      <Loader v-show="isLoading" />
    </div>
  </div>
</template>

<script>
import MainHeader from '@/shared/components/MainHeader.vue'
import { useRouteGuard } from '@/shared/composables/useRouteGuard.js'
import { alert_question_circle, arrow_left } from '@/shared/constants/icons.js'

export default {
  name: 'PageContent',
  components: {
    MainHeader
  },
  props: {
    heading: {
      type: Object,
      default() {
        return {
          title: '',
          description: '',
          useTranslation: false
        }
      }
    },
    backPath: {
      type: String,
      default() {
        return '/dashboard'
      }
    },
    backText: {
      type: String,
      default() {
        return 'Backoffice'
      }
    },
    isHelpEnabled: Boolean,
    isLoading: Boolean,
    isEmpty: Boolean
  },
  emits: ['didClickHelp'],
  setup() {
    const noCypress = !window['Cypress']
    if (noCypress) {
      const { isSecuringRoute } = useRouteGuard()
      return { isSecuringRoute }
    } else {
      return {}
    }
  },
  data() {
    return {
      arrow_left,
      alert_question_circle
    }
  },
  computed: {
    hasHeadingContent() {
      return this.heading.title !== '' && this.heading.description !== ''
    }
  },
  methods: {
    clickHelp() {
      this.$emit('didClickHelp')
    }
  }
}
</script>

<style lang="scss" scoped>
.page-heading-nav {
  margin-top: 1em;
  display: flex;
  grid-gap: 4px;
  align-items: center;
  justify-content: space-between;
}
a {
  text-decoration: none;
}
</style>
