<template>
  <pn-modal :open="show" class="email-modal content" @close="closeModal">
    <h2>{{ title }}</h2>
    <pn-input
      type="email"
      class="main-input"
      :label="$t('APP.EMAIL')"
      :value="draftEmail"
      :error="error"
      @input="draftEmail = $event.target.value"
    ></pn-input>
    <div class="actions-right">
      <pn-button appearance="light" class="cancel" @click="closeModal">
        {{ $t('APP.CANCEL') }}
      </pn-button>
      <pn-button
        v-show="isValid"
        :icon="arrow_right"
        class="send"
        @click="send"
      >
        {{ $t('APP.SEND') }}
      </pn-button>
    </div>
  </pn-modal>
</template>

<script>
import useValidators from '@/shared/composables/useValidators.js'
import { RETAIL_EMAIL_TO } from '@/shared/constants/cache.js'
import { arrow_right } from '@/shared/constants/icons.js'

export default {
  name: 'EmailInputModal',
  props: {
    show: Boolean,
    title: {
      type: String,
      default() {
        return ''
      }
    },
    email: {
      type: String,
      default() {
        return localStorage.getItem(RETAIL_EMAIL_TO) || ''
      }
    }
  },
  emits: ['didClose', 'didSend'],
  setup() {
    const { isEmail } = useValidators()
    return { isEmail }
  },
  data() {
    return {
      draftEmail: '',
      arrow_right
    }
  },
  computed: {
    isValid() {
      return this.isEmail(this.draftEmail)
    },
    error() {
      if (this.draftEmail === '' || this.isValid) {
        return ''
      } else {
        return 'Input a valid email'
      }
    }
  },
  watch: {
    email: {
      immediate: true,
      handler(val) {
        this.draftEmail = val
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('didClose')
    },
    send() {
      localStorage.setItem(RETAIL_EMAIL_TO, this.draftEmail)
      this.$emit('didSend', this.draftEmail)
    }
  }
}
</script>
<style lang="scss" scoped>
.main-input {
  width: 100%;
  margin-bottom: 3em;
}
</style>
