<template>
  <PageContent :heading="heading">
    <ConfirmModal
      :message="deleteModal.getMessage()"
      :show="deleteModal.show"
      @cancel="deleteModal.show = false"
      @confirm="confirmDeleteCustomer"
    />
    <div class="main-content">
      <div class="column">
        <CustomerList
          :is-loading="loading"
          :selected="selected"
          @did-select="handleDidSelectCustomer"
          @will-add-customer="addCustomer"
          @will-remove-customer="removeCustomer"
        />
      </div>
      <div class="column boredered">
        <CustomerForm
          :show="showForm"
          :is-loading="loading"
          :customer="selected"
          @cancel="handleCancelForm"
          @save="handleSaveForm"
          @did-save-with-error="handleSaveWithError"
        />
      </div>
    </div>
  </PageContent>
</template>

<script>
import PageContent from '@/shared/components/PageContent.vue'
import { mapActions, mapState } from 'vuex'

import CustomerForm from './components/CustomerForm.vue'
import CustomerList from './components/CustomerList.vue'

import { alert_exclamation_triangle, check } from '@/shared/constants/icons.js'

const removeSelectedStyleClass = () => {
  const elements = document.getElementsByClassName('item-row')
  if (typeof elements.forEach === 'function') {
    elements?.forEach((element) => {
      element.classList.remove('selected')
    })
  }
}

export default {
  name: 'CustomerManagement',
  components: {
    PageContent,
    CustomerForm,
    CustomerList
  },
  data() {
    return {
      heading: {
        title: 'CUSTOMER_MANAGEMENT.TITLE',
        description: 'CUSTOMER_MANAGEMENT.DESCRIPTION',
        useTranslation: true
      },
      showForm: false,
      selected: null,
      successToast: {
        showClose: true,
        icon: check,
        appearance: 'success',
        message: '',
        show: true
      },
      deleteModal: {
        data: null,
        show: false,
        getMessage: () => {
          if (this.deleteModal.data) {
            return `Are you sure to remove customer: ${this.deleteModal.data.companyName}?`
          }
          return ''
        }
      },
      storeSubscription: null
    }
  },
  computed: {
    ...mapState(['loading'])
  },
  watch: {
    selected(val) {
      if (val === null) {
        removeSelectedStyleClass()
      }
    }
  },
  mounted() {
    this.subscribeMutations()
    this.resetLocalState()
  },
  unmounted() {
    if (this.storeSubscription) {
      this.storeSubscription()
    }
  },
  methods: {
    ...mapActions('deliveryAgreements', [
      'getDeliveryAgreements',
      'createDeliveryAgreement',
      'updateDeliveryAgreement',
      'deleteDeliveryAgreement'
    ]),
    ...mapActions('toaster', ['showToaster']),
    resetLocalState() {
      this.showForm = false
      this.selected = null
      this.getDeliveryAgreements()
    },
    addCustomer() {
      this.showForm = true
      this.selected = null
    },
    removeCustomer(customer) {
      this.deleteModal.data = customer
      this.deleteModal.show = true
    },
    confirmDeleteCustomer() {
      const id = this.deleteModal.data.id
      if (id) {
        this.deleteDeliveryAgreement(id)
      }
      this.deleteModal.show = false
    },
    handleDidSelectCustomer(customer) {
      this.showForm = true
      this.selected = customer
    },
    handleCancelForm() {
      this.selected = null
      this.showForm = false
    },
    handleSaveForm(customer) {
      if (customer.id) {
        this.updateDeliveryAgreement(customer)
      } else {
        this.createDeliveryAgreement(customer)
      }
    },
    handleSaveWithError(error) {
      const toast = {
        showClose: true,
        icon: alert_exclamation_triangle,
        appearance: 'warning',
        message: error,
        show: true
      }
      this.showToaster(toast)
    },
    subscribeMutations() {
      const mutationsToObserve = [
        'deliveryAgreements/didCreate',
        'deliveryAgreements/didUpdate',
        'deliveryAgreements/didDelete'
      ]

      const getMessageFromMutationType = (type) => {
        let message = 'Successfully created new customer'
        if (type === 'deliveryAgreements/didUpdate') {
          message = 'Successfully updated customer'
        } else if (type === 'deliveryAgreements/didDelete') {
          message = 'Successfully deleted customer'
        }
        return message
      }

      this.storeSubscription = this.$store.subscribe((mutation) => {
        if (mutationsToObserve.includes(mutation.type)) {
          this.successToast.message = getMessageFromMutationType(mutation.type)
          this.showToaster(this.successToast)
          this.resetLocalState()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  display: flex;
  flex-wrap: wrap;
  margin: 2em 0;

  .column {
    flex: 1;
    // margin: 0 1em;
    padding-right: 2em;
  }

  .boredered {
    border-left: solid 2px $gray50;
    padding-left: 2em;
  }
}

@media (max-width: 700px) {
  .main-content {
    .column {
      width: 100%;
      padding-right: unset;
      flex: unset;
    }

    .boredered {
      border-left: unset;
      padding-left: unset;
    }
  }
}
</style>
