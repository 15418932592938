import { alert_exclamation_circle } from '@/shared/constants/icons.js'
import { b64toBlob } from '@postnord/retail-backoffice-components/dist'
import { reactive } from 'vue'

export default function usePrint() {
  const generateReportErrorToast = {
    icon: alert_exclamation_circle,
    appearance: 'warning',
    message: 'Unable to generate report data.'
  }
  const printModal = reactive({
    show: false,
    url: null,
    isLoading: false
  })

  const printPdf = (data) => {
    openPdf(prepareForPrint(data))
  }

  const prepareForPrint = (data) => {
    return URL.createObjectURL(b64toBlob(data, 'application/pdf'))
  }

  const openPdf = (fileUrl) => {
    window.open(fileUrl) || window.location.assign(fileUrl)
  }

  return {
    generateReportErrorToast,
    printModal,
    printPdf,
    prepareForPrint,
    openPdf
  }
}
