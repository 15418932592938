<template>
  <div class="content">
    <pn-button :icon="plus" class="new-customer" @click="addCustomer">{{
      $t('CUSTOMER_MANAGEMENT.NEW_CUSTOMER')
    }}</pn-button>
    <div class="flex-group">
      <h4>{{ $t('CUSTOMER_MANAGEMENT.REGISTERED_CUSTOMERS') }}</h4>
      <pn-search-field
        :placeholder="$t('CUSTOMER_MANAGEMENT.FILTER_CUSTOMERS')"
        button="icon-inline"
        :disabled="isLoading"
        @update="filterByQuery($event.detail)"
      />
    </div>
    <div v-if="!isLoading" class="list">
      <ItemHeaders
        :headers="headers"
        :use-translation="true"
        :extra-columns="1"
        @sort="sortByProperty($event)"
      />
      <div
        v-for="(customer, index) in visibleItems"
        :key="index"
        class="item-row"
        :class="{
          selected: getIfSelected(customer)
        }"
        @click="select(customer)"
      >
        <div class="column">
          <span class="highlighted-label">
            {{ customer.companyName }}
          </span>
        </div>
        <div class="column">{{ customer.customerNumber }}</div>
        <div class="actions">
          <pn-button
            appearance="warning"
            :icon="trash_can"
            @click="remove(customer)"
            >{{ $t('APP.REMOVE') }}</pn-button
          >
        </div>
      </div>
      <NavPagination
        v-if="filteredItems.length"
        :lists="filteredItems"
        :per-page="10"
        :total="filteredItems.length"
        @page-changed="updatePage"
      />
    </div>
    <Loader v-else />
    <EmptyContent v-show="!isLoading && isEmpty" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ItemHeaders from '@/shared/components/ItemHeaders.vue'
import NavPagination from '@/shared/components/NavPagination.vue'
import { plus, trash_can } from '@/shared/constants/icons.js'
import genericListMixin from '@/shared/mixins/genericListMixin.js'

export default {
  name: 'CustomerList',
  components: {
    NavPagination,
    ItemHeaders
  },
  mixins: [genericListMixin],
  props: {
    isLoading: Boolean,
    selected: {
      type: Object,
      default() {
        return null
      }
    }
  },
  emits: ['didSelect', 'willAddCustomer', 'willRemoveCustomer'],
  data() {
    return {
      selectedDraft: null,
      headers: [
        {
          label: 'CUSTOMER_MANAGEMENT.NAME',
          isSortable: true,
          sortKey: 'companyName'
        },
        {
          label: 'CUSTOMER_MANAGEMENT.CUSTOMER_NUMBER',
          isSortable: true,
          sortKey: 'customerNumber'
        }
      ],
      plus,
      trash_can
    }
  },
  computed: {
    ...mapState('deliveryAgreements', ['items']),
    isEmpty() {
      return this.items?.length === 0
    }
  },
  watch: {
    selected(val) {
      this.selectedDraft = val
    },
    items: {
      immediate: true,
      handler(val) {
        this.mainItems = val
      }
    }
  },
  methods: {
    select(customer) {
      this.selectedDraft = customer
      this.$emit('didSelect', customer)
    },
    remove(customer) {
      this.$emit('willRemoveCustomer', customer)
      this.select(customer)
    },
    addCustomer() {
      this.$emit('willAddCustomer')
    },
    getIfSelected(customer) {
      return this.selected?.id === customer.id
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: block;
  font-weight: 500;
}

.new-customer {
  margin-bottom: 1em;
}

.item-row {
  cursor: pointer;
}

.column {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 20%;
  overflow-wrap: break-word;
}

.label {
  color: $blue700;
}

.flex-group {
  justify-content: space-between;
}
</style>
