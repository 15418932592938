<template>
  <PageContent :heading="heading" :is-loading="loading">
    <PrintOpenModal
      :show="printModal.show"
      :is-loading="printModal.isLoading"
      :url="printModal.url"
      @did-close="printModal.show = false"
    />
    <DeliveryListsFilterControl
      :selected-states="selectedStates"
      @did-update-id="handleUpdateFilterId"
      @did-search-id="handleDidSearchId"
      @did-select-agreement="handleDidSelectAgreement"
      @did-change-date-range="handleDidChangeDateRange"
      @did-clear-filters="handleDidClearFilters"
      @did-update-selected-states="handleUpdateSelectedStates"
    />
    <MultiplePrintControl
      v-show="!loading && printableItems.length"
      :checked="isPrintAll"
      :indeterminate="isPrintAllIndeterminate"
      :printable-count="printableItems.length"
      :for-print-count="selectedIds.length"
      @toggle-select="toggleMultiplePrintSelection"
      @print="printMultipleLists"
    />
    <div v-if="!loading">
      <ItemSearchResult
        v-if="isItemSearch"
        :item="filteredItems.length ? filteredItems[0] : null"
        :search-text="filters.id"
        @did-select="handleDidSelect"
      />
      <DeliveryListsTable
        v-else
        :items="filteredItems"
        :search-text="filters.id"
        :selected-ids="selectedIds"
        @did-select="handleDidSelect"
        @did-update-selected-items="handleDidUpdateSelectedItems"
      />
    </div>
  </PageContent>
</template>

<script>
import PageContent from '@/shared/components/PageContent.vue'
import PrintOpenModal from '@/shared/components/PrintOpenModal.vue'

import usePrint from '@/shared/composables/usePrint.js'
import { mapActions, mapState } from 'vuex'
import DeliveryListsFilterControl from './components/DeliveryListsFilterControl.vue'
import DeliveryListsTable from './components/DeliveryListsTable.vue'
import ItemSearchResult from './components/ItemSearchResult.vue'
import MultiplePrintControl from './components/MultiplePrintControl.vue'
import useMultiplePrint from './composables/useMultiplePrint.js'
import useSearchFilters from './composables/useSearchFilters.js'
import { heading } from './constants'

export default {
  name: 'DeliveryLists',
  components: {
    PageContent,
    PrintOpenModal,
    DeliveryListsTable,
    DeliveryListsFilterControl,
    ItemSearchResult,
    MultiplePrintControl
  },
  setup() {
    const { filters, cleanFilters, clearFilterState } = useSearchFilters()
    const { createUrlFromBase64Array } = useMultiplePrint()
    const { printModal } = usePrint()
    return {
      filters,
      cleanFilters,
      clearFilterState,
      createUrlFromBase64Array,
      printModal
    }
  },
  data() {
    return {
      heading,
      filteredItems: [],
      isPrintAll: false,
      selectedIds: [],
      selectedStates: [],
      storeSubscription: null
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapState('deliverylists', ['items']),
    isItemSearch() {
      return (
        this.filters.id &&
        !this.filters.id.includes('dlfa_') &&
        this.filteredItems.length === 1 &&
        this.filteredItems[0].items.filter(
          (item) => item.itemId === this.filters.id
        ).length === 1
      )
    },
    printableItems() {
      return this.filteredItems.filter((item) => item.state !== 'delivered')
    },
    isPrintAllIndeterminate() {
      return (
        this.selectedIds.length !== 0 &&
        this.selectedIds.length !== this.printableItems.length
      )
    }
  },
  watch: {
    items: {
      immediate: true,
      handler(val) {
        this.clearLocalFilters()
        this.filteredItems = this.getSortedArrayByPriority(val)
      }
    },
    filteredItems(val) {
      if (this.selectedIds.length) {
        const filtered = val.map((item) => item.deliveryListId)
        this.selectedIds = this.selectedIds.filter((id) =>
          filtered.includes(id)
        )
      }
    },
    selectedIds(val) {
      if (val.length === this.printableItems.length) {
        this.isPrintAll = true
      } else if (val.length === 0) {
        this.isPrintAll = false
      }
    }
  },
  mounted() {
    this.subscribeMutations()
    this.search()
  },
  unmounted() {
    if (this.storeSubscription) {
      this.storeSubscription()
    }
  },
  methods: {
    ...mapActions('deliverylists', [
      'search',
      'selectDeliveryList',
      'printMultipleDeliveryList'
    ]),
    handleDidSelect(item) {
      this.selectDeliveryList(item)
      this.$router.push({
        path: `/delivery-list/${item.deliveryListId}`
      })
    },
    handleDidSearchId(event) {
      let payload = {}
      if (event) {
        payload = { id: event }
      }
      this.search(payload)
    },
    handleDidSelectAgreement(event) {
      this.filters.agreementId = event
      this.search(this.cleanFilters)
    },
    handleDidChangeDateRange(event) {
      this.filters.dateFrom = event.dateFrom
      this.filters.dateTo = event.dateTo
      this.search(this.cleanFilters)
    },
    clearLocalFilters() {
      this.isPrintAll = false
      this.selectedIds = []
      this.selectedStates = []
    },
    handleDidClearFilters() {
      this.clearFilterState()
      this.clearLocalFilters()
      this.search()
    },
    getSortedArrayByPriority(items) {
      const statusToTop = 'readyForPrint'
      return items.slice().sort((a, b) => {
        if (a.state === statusToTop && b.state !== statusToTop) {
          return -1
        } else if (a.state !== statusToTop && b.state === statusToTop) {
          return 1
        } else {
          return a.state < b.state ? 1 : -1
        }
      })
    },
    printMultipleLists() {
      this.printMultipleDeliveryList(this.selectedIds)
    },
    async handlePrintData(data) {
      this.printModal.show = true
      this.printModal.isLoading = true
      this.printModal.url = await this.createUrlFromBase64Array(data)
      this.printModal.isLoading = false
    },
    toggleMultiplePrintSelection(event) {
      this.isPrintAll = !event
      if (this.isPrintAll) {
        this.selectedIds = this.printableItems.map(
          (item) => item.deliveryListId
        )
      } else {
        this.selectedIds = []
      }
    },
    handleUpdateSelectedStates(states) {
      this.selectedStates = [...states]
      this.applyCurrentFilters()
    },
    getFilteredByText(items, text) {
      return items.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(text.toLowerCase())
      )
    },
    getFilteredByStates(items, states) {
      return items.filter((item) => states.includes(item.state))
    },
    applyCurrentFilters() {
      let newItems = this.items
      if (this.filters.id) {
        newItems = this.getFilteredByText(newItems, this.filters.id)
      }
      if (this.selectedStates.length) {
        newItems = this.getFilteredByStates(newItems, this.selectedStates)
      }
      this.filteredItems = this.getSortedArrayByPriority(newItems)
    },
    handleUpdateFilterId(event) {
      this.filters.id = event
      this.applyCurrentFilters()
    },
    handleDidUpdateSelectedItems(event) {
      this.selectedIds = [...event]
    },
    subscribeMutations() {
      this.storeSubscription = this.$store.subscribe((mutation) => {
        if (mutation.type === 'deliverylists/didPrintList') {
          const allDataOnly = mutation?.payload.map((item) => item.data)
          this.handlePrintData(allDataOnly)
          this.clearLocalFilters()
        }
      })
    }
  }
}
</script>
