<template>
  <div class="item-row header row">
    <div
      v-for="(header, index) in headers"
      :key="index"
      class="column"
      :class="{ sortable: header.isSortable, extend: header.isExtended }"
      @click="sort(header.sortKey)"
    >
      {{ useTranslation ? $t(header.label) : header.label }}
      <pn-icon
        v-if="header.isSortable"
        :symbol="unfold"
        small
        color="blue700"
      />
    </div>
    <div v-for="index2 in extraColumns" :key="index2" class="column"></div>
  </div>
</template>

<script>
import { unfold } from '@/shared/constants/icons.js'

export default {
  name: 'ItemHeaders',
  props: {
    useTranslation: Boolean,
    extraColumns: {
      type: Number,
      default() {
        return 0
      }
    },
    headers: {
      type: Array,
      default() {
        /**
         * Value should follow the object structure
         * [
         *   label,
         *   isSortable
         *   sortKey
         * ]
         */
        return []
      }
    }
  },
  emits: ['sort'],
  data() {
    return {
      unfold
    }
  },
  methods: {
    sort(sortKey) {
      this.$emit('sort', sortKey)
    }
  }
}
</script>

<style lang="scss" scoped>
.column {
  flex: 1;
}

.column.extend {
  flex: 1.7;
}
</style>
