import { decoratedRequestWithCommonHeaders } from '@/shared/services/serviceDecorators.js'
import axios from 'axios'

const { VUE_APP_BASEREGISTER_BASE_URL } = process.env

axios.defaults.withCredentials = true

const serviceInstance = axios.create({
  baseURL: `${VUE_APP_BASEREGISTER_BASE_URL}`
})

serviceInstance.interceptors.request.use((request) => {
  return decoratedRequestWithCommonHeaders(request)
})

export default {
  login: async (token) => {
    const headers = {
      Authorization: token
    }
    return serviceInstance.get('/authentication', { headers })
  }
}
