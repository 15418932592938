<template>
  <pn-modal :open="show" class="help-modal" @close="close">
    <h1>{{ $t('APP.HELP') }}</h1>
    <pn-accordion>
      <pn-accordion-row label="Forecast Health">
        <ForecastHealth
          :should-check="show"
          @did-change-status="isUp = $event"
        />
      </pn-accordion-row>
      <pn-accordion-row :label="$t('FORECAST.RESPONSIBLE_PARTY')">
        {{ $t('FORECAST.RESPONSIBLE_PARTY_INFO') }}
      </pn-accordion-row>
      <pn-accordion-row :label="$t('FORECAST.FORECAST_CALCULATION')">
        {{ $t('FORECAST.PROJECTIONS_MESSAGE') }}
      </pn-accordion-row>
      <pn-accordion-row :label="$t('FORECAST.FORECASTS_NOT_AVAILABLE')">
        {{ $t('FORECAST.FORECASTS_NOT_AVAILABLE_INFO') }}
      </pn-accordion-row>
      <pn-accordion-row :label="$t('FORECAST.UNABLE_TO_LOGIN')">
        {{ $t('FORECAST.UNABLE_TO_LOGIN_INFO') }}
      </pn-accordion-row>
    </pn-accordion>
  </pn-modal>
</template>

<script>
import ForecastHealth from './ForecastHealth'

export default {
  name: 'HelpModal',
  components: {
    ForecastHealth
  },
  props: {
    show: Boolean
  },
  emits: ['cancel'],
  data() {
    return {
      isUp: true
    }
  },
  methods: {
    close() {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.health-label {
  display: flex;
  grid-gap: 4px;
  align-items: center;
}
</style>
